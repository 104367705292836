import { Capacitor } from '@capacitor/core'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'

const isNative = Capacitor.isNativePlatform()

if (process.env.DISABLE_ANALYTICS === 'yes') window.disableAnalytics = true

const setUserId = (userId) => {
  // GTM
  if (dataLayer && typeof dataLayer.push === 'function') {
    dataLayer.push({ userId })
  }

  // amplitude

  if (process.env.APP === 'people') {
    window.disableAnalytics || (window.amplitude && amplitude.setUserId(userId))

    // Firebase
    if (isNative) FirebaseAnalytics.setUserId({ userId })
  } else {
    window.disableAnalytics || window.Cypress || (window.amplitude && amplitude.getInstance().setUserId(userId))
  }
}

const setProperties = (properties, userId) => {
  if (window.disableAnalytics || window.Cypress) {
    console.log('[System] Analytics Disabled, Property:', { properties: userId })
    return
  }

  // GTM
  if (dataLayer && typeof dataLayer.push === 'function') {
    dataLayer.push({
      event: 'UserProps',
      user_props: properties
    })
  }

  if (process.env.APP === 'payroller') {
    // Amplitude
    window.amplitude && amplitude.getInstance().setUserProperties(properties)

    // Firebase
    if (isNative) FirebaseAnalytics.setUserProperty(properties)
  }

  // NOTE: Amplitude's new sdk depricated setUserProperties
}

let logEvent = function (eventName, eventProperties, callback, onErrorCallback) {
  if (window.disableAnalytics || window.Cypress) {
    console.log('[System] Analytics Disabled, Event:', { [eventName]: eventProperties })
    return
  }
  try {
    eventProperties = eventProperties || {}
    eventProperties.platform = 'web'
    eventProperties.source_app = window.location.hostname ?? process.env.PLATFORM_ENVIRONMENT

    // GTM
    if (dataLayer && typeof dataLayer.push === 'function') {
      dataLayer.push({
        event: eventName.replace(/\s+/g, ''),
        ...eventProperties
      })
    }

    if (process.env.APP === 'people') {
      window.amplitude && amplitude.logEvent(eventName, eventProperties)

      // Firebase
      if (isNative) {
        FirebaseAnalytics.logEvent({
          name: eventName.replace(/\s+/g, ''),
          params: eventProperties
        })
      }
    } else {
      if (onErrorCallback) {
        try {
          // Identify if user has some kind of ad blocker / privacy extension
          // try to get session id to identify if amplitude has init sucessfully
          amplitude.getInstance().getSessionId()
        } catch {
          onErrorCallback()
        }
      }
      window.amplitude && amplitude?.getInstance()?.logEvent(eventName, eventProperties, callback, onErrorCallback)
    }
  } catch (e) {
    console.error(e)
  }
}

export { setUserId, setProperties, logEvent }
