import { setModals, setShowHelpMenu, setIsKeyboardVisible, setIsTsrOnly } from '../../reducers/appSlice'
import { hideSubBanner } from '../../reducers/subscriptionSlice'
import { SafeArea } from 'capacitor-plugin-safe-area'
import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'
import momentTimezone from 'moment-timezone'

import { setModalSubscription } from '../../scheduling/react-subscription/utils/share'
import { PAYRUN_LENGTH_FOR_GOOGLE_REVIEW } from '../consts'
import { ngGetInitialData } from '../../services'
import { isValidMobileSubscription } from '../../utils/subscription'
import { postData } from '@bookipi/utils'
import baseStyle from 'tailwindconfig'

export let updateParticipant = () =>
  console.warn('Empty function - updateParticipant: Angular controller is not loaded. (businessCtrl.js)')
export let openSuperSettingModal = () =>
  console.warn('Empty function - openSuperSettingModal: Angular controller is not loaded. (businessCtrl.js)')
export let showGoogleReview = () =>
  console.warn('Empty function - showGoogleReview: Angular controller is not loaded. (businessCtrl.js)')
export let getInitialData = () =>
  console.warn('Empty function - getInitialData: Angular controller is not loaded. (businessCtrl.js)')

export let getInitialDataByYear = (year) =>
  console.warn('Empty function - getInitialData: Angular controller is not loaded. (businessCtrl.js)')

export let checkIsAgent = () =>
  console.warn('Empty function - checkIsAgent: Angular controller is not loaded. (businessCtrl.js)')
export let openPayrunAutomationOptInModal = () =>
  console.warn('Empty function - showGoogleReview: Angular controller is not loaded. (businessCtrl.js)')

let initialDataLoaded = false

angular
  .module('starter.business.controllers', [])
  .controller(
    'businessCtrl',
    function (
      $scope,
      $rootScope,
      $state,
      GLOBALS,
      Http,
      Http2,
      Service,
      Logout,
      $timeout,
      $window,
      CompanyAction,
      BEAM_URL,
      Analytics,
      $ngRedux,
      HttpPromiseAndLocalStorage,
      UserAuthFactory,
      API_ENDPOINT,
      $stateParams,
      $location,
      RefreshTokenNoDebounce
    ) {
      $scope.appState = {}
      $scope.currentUrl = ''
      $ngRedux.connect((state) => state.app)($scope.appState)
      $scope.v2Url = `${process.env.DEV_V2}/agent/clients`
      $rootScope.$state = $state
      $rootScope.isSandbox = process.env.IS_SANDBOX === 'true'
      $rootScope.tabView = window.innerWidth < 1400
      $rootScope.mobileView = window.innerWidth < 1366
      $rootScope.innerWidth = window.innerWidth
      $rootScope.isProd = process.env.P_NODE_ENV === 'production'
      $scope.subscription = {}
      $scope.appRedux = {}
      $scope.isTsrOnly = process.env.APP === 'people'
      $scope.token = $window.localStorage.pr_token
      $scope.analytics = Analytics
      $rootScope.isOverlayVisible = false
      $rootScope.isEsignOverlayVisible = false
      $rootScope.isEsignOverlayLightVisible = false
      $rootScope.isPopoverOverlayVisible = false
      $scope.appState.isPeopleV2 = false
      if ($stateParams.isRedirectedFromV2 === 'true') {
        $rootScope.isRedirectedFromV2 = true
      }

      $rootScope.toggleSandbox = () => {
        const isSandBox = $window.localStorage.getItem('sandboxMode')
        if (isSandBox) {
          $window.localStorage.removeItem('sandboxMode')
        } else {
          $window.localStorage.setItem('sandboxMode', 'true')
        }
      }

      $rootScope.isSandboxMode = !!$window.localStorage.sandboxMode

      /**
       * Handle query parameter if it is tsr only
       */
      if ($stateParams.isTsrOnly === 'true') {
        $ngRedux.dispatch(setIsTsrOnly(true))
        $scope.isTsrOnly = true
      }

      $rootScope.$on('$locationChangeStart', function (e, next, current) {
        $scope.currentUrl = next
      })

      /**
       * Handle query parameter if it is tsr only
       */
      if ($stateParams.isTsrOnly === 'true') {
        $ngRedux.dispatch(setIsTsrOnly(true))
        $scope.isTsrOnly = true
      }

      $scope.mount = () => {
        SafeArea.getSafeAreaInsets().then(({ insets }) => {
          const additionalSafeArea = Capacitor.getPlatform() === 'ios' ? 0 : SafeArea.getStatusBarHeight()
          document.getElementsByClassName('mainContentContainer')[0].style.marginTop = `${
            insets.top - additionalSafeArea
          }px`
          document.body.style['overflow-y'] = 'hidden !important'
        })
      }

      const toggleIframeIndex = (index) => {
        const allowedPathsToToggle = ['/business/schedule/rosters-v2']
        const path = $location.path()
        if (!allowedPathsToToggle.includes(path)) return

        const elem = document.getElementById('roster-v2')
        if (elem) elem.style.zIndex = index
      }

      // -----
      // Modal overlay event listener for Modal v2(Modal.tsx)
      // apps/frontend-v2/app/components/Modal.tsx
      $window.onmessage = (event) => {
        if (event.data === 'modal-overlay-opened') {
          toggleIframeIndex(999)
          $rootScope.isOverlayVisible = true
          $rootScope.$apply()
        } else if (event.data === 'modal-overlay-closed') {
          toggleIframeIndex(1)
          $rootScope.isOverlayVisible = false
          $rootScope.$apply()
        } else if (event.data === 'popover-overlay-opened') {
          toggleIframeIndex(999)
          $rootScope.isPopoverOverlayVisible = true
          $rootScope.$apply()
        } else if (event.data === 'popover-overlay-closed') {
          toggleIframeIndex(1)
          $rootScope.isPopoverOverlayVisible = false
          $rootScope.$apply()
        } else if (event.data === 'people-v2-signout') {
          $window.location.href = '/login'
          Logout()
        } else if (event.data === 'modal-settings-closed') {
          $rootScope.isOverlayVisible = false
          $rootScope.$apply()
          $state.go($location.search().from || 'business.run.overview')
        } else if (event.data === 'open-paywall') {
          $ngRedux.dispatch(
            setModalSubscription({
              source: 'integrateXero'
            })
          )
        } else if (event.data === 'notification-drawer-closed') {
          $scope.notificationState.open = false
        } else if (event.data === 'xero-connect') {
          window.open(`${API_ENDPOINT.url}/xero/connect/${$rootScope.company._id}`, '_self')
        } else if (event.data === 'beam-connect') {
          updateParticipant()
        } else if (event.data === 'refetch-initial-data') {
          // Reload the whole iframe to refetch initial data
          const settingRevampIframe = document.getElementById('settings-v2')
          settingRevampIframe.src += '&tab=add-ons'
          $rootScope.isOverlayVisible = false
          $rootScope.$apply()
        } else if (event.data === 'refetch-initial-data-v1') {
          $scope.getInitialData()
        } else if (event.data === 'connect-beam') {
          Http(
            {
              method: 'get',
              api: '/v1/super/connect-link',
              disableErrorMsg: true
            },
            (res) => {
              if (res.token) {
                window.location.replace(BEAM_URL + '/#access_token=' + res.token)
              } else {
                Service.alert()
              }
            },
            () => {
              Service.alert()
            }
          )
        } else if (event.data === 'redirect-to-agent-portal') {
          const UX_REVAMP_URL = process.env.DEV_V2
          const token = $window.localStorage.pr_token
          const expires = $window.localStorage.pr_expires
          $window.location.replace(`${UX_REVAMP_URL}/agent/clients?t=${token}&e=${expires}&userType=agent`)
          if (window !== undefined) window.localStorage.clear()
        } else if (event.data === 'e-sign-overlay-open') {
          $rootScope.isEsignOverlayVisible = true
          $rootScope.$apply()
          const sideBar = document.getElementById('sideBar')
          sideBar.style.zIndex = 1
        } else if (event.data === 'e-sign-overlay-light-open') {
          $rootScope.isEsignOverlayLightVisible = true
          $rootScope.$apply()
        } else if (event.data === 'e-sign-overlay-close') {
          $rootScope.isEsignOverlayVisible = false
          $rootScope.$apply()
        } else if (event.data === 'e-sign-overlay-light-close') {
          $rootScope.isEsignOverlayLightVisible = false
          const sideBar = document.getElementById('sideBar')
          sideBar.style.zIndex = baseStyle.theme.extend.zIndex.sideBar
          $rootScope.$apply()
        } else if (event.data === 'e-sign-fullscreen') {
          const documentsContent = document.getElementById($rootScope.mobileView ? 'mainContent' : 'documents-v2')
          documentsContent.style.position = 'absolute'
          documentsContent.style.top = '0'
          documentsContent.style.left = '0'
          documentsContent.style.right = '0'
          const sideBar = document.getElementById('sideBar')
          sideBar.style.zIndex = 1
        } else if (event.data === 'e-sign-reset') {
          const documentsContent = document.getElementById($rootScope.mobileView ? 'mainContent' : 'documents-v2')
          documentsContent.style.position = 'relative'
        }
      }

      $scope.onOverlayClick = () => {
        const peopleRevampIframe = document.getElementById('people-v2')
        const settingsRevampIframe = document.getElementById('settings-v2')
        const rosterRevampIframe = document.getElementById('roster-v2')
        const documentRevampIframe = document.getElementById('documents-v2')
        const iframe = peopleRevampIframe || settingsRevampIframe || rosterRevampIframe || documentRevampIframe
        iframe?.contentWindow?.postMessage('modal-overlay-clicked', '*')
      }

      // -----

      if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') {
        $scope.mount()
      }

      checkIsAgent = () => $rootScope.isAgent
      $scope.reactState = {
        HttpPromise: HttpPromiseAndLocalStorage,
        helpers: Service,
        API_ENDPOINT: API_ENDPOINT,
        isAgent: $rootScope.isAgent
        // refDate: $rootScope.company?.features?.test_dateNow
      }
      const checkWidth = () => {
        $rootScope.tabView = window.innerWidth < 1400
        $rootScope.mobileView = window.innerWidth < 1366
        $rootScope.innerWidth = window.innerWidth
        const newShowHelp = window.innerWidth > 1400
        if ($rootScope.showHelpMenu !== newShowHelp) $ngRedux.dispatch(setShowHelpMenu(newShowHelp))
      }

      addEventListener('resize', checkWidth)

      // Add keyboard event listener
      if (Capacitor.isNativePlatform()) {
        const onShow$ = Keyboard.addListener('keyboardWillShow', () => {
          $ngRedux.dispatch(setIsKeyboardVisible(true))
        })
        const onHide$ = Keyboard.addListener('keyboardWillHide', () => {
          $ngRedux.dispatch(setIsKeyboardVisible(false))
        })

        $scope.$on('$destroy', function () {
          onShow$.remove()
          onHide$.remove()
        })
      }

      $ngRedux.connect((state) => state.subscription)($scope.subscription)
      // emp super rate react -> angular state
      $ngRedux.connect((state) => {
        if (!state.app?.employees) {
          return {}
        }
        if (!$rootScope.employees) {
          return {}
        }
        return {
          employees: state.app.employees.map((emp) => {
            let root = $rootScope.employees.find((x) => x._id === emp._id)
            return {
              ...root,
              s_r: emp.s_r
            }
          }),
          gbFeatures: {
            ...state.app?.gbFeatures,
            bds_nav_bar_v1: true
          },
          ...(state.app?.company?.superIncrementStatus && {
            company: {
              ...$rootScope.company,
              superIncrementStatus: {
                ...state.app.company.superIncrementStatus
              }
            }
          }),
          showHelpMenu: state.app?.showHelpMenu,
          isTsrOnly: state.app?.isTsrOnly
        }
      })($rootScope)

      $rootScope.gbFeatures = {
        ...$rootScope.gbFeatures,
        bds_nav_bar_v1: true
      }

      // Initially check width to prevent
      // incorrect display of help menu
      checkWidth()

      $scope.displayNewUI = () => {
        const revampView = $scope?.appState?.gbFeatures?.ui_revamp_display

        if (!revampView) return false

        const path = $window.location?.pathname

        if (
          path.includes('/business/people-v2') ||
          path.includes('/business/settings-v2') ||
          path.includes('/business/reports-v2') ||
          path.includes('/business/superstream-v2') ||
          path.includes('/business/notification-v2') ||
          path.includes('/business/documents')
        ) {
          return true
        }

        const allowedPaths = {
          roster: '/business/schedule/rosters',
          timesheet: '/business/schedule/timesheets',
          leave: '/business/leaves',
          'people-v2': '/business/people-v2',
          'settings-v2': '/business/settings-v2',
          'notification-v2': '/business/notification-v2',
          documents: '/business/documents'
        }

        const pathKeys = Object.keys(allowedPaths)
        for (let i = 0; i < pathKeys.length; i++) {
          if (allowedPaths[pathKeys[i]] === path && revampView[pathKeys[i]]) {
            return true
          }
        }

        $scope.appState.isPeopleV2 = false
        return false
      }

      $rootScope.getDownloadEndpoint = function (platform) {
        const links = {
          android: 'https://play.google.com/store/apps/details?id=payroll.payslip.free.app.payrun',
          ios: 'https://apps.apple.com/au/app/id1464478416?ls=1'
        }
        return links[platform]
      }

      $rootScope.showWhiteBg = function () {
        const flags = $rootScope?.gbFeatures?.ui_revamp_display ?? {}
        const path = window.location.pathname
        const paths = {
          roster: '/business/schedule/rosters',
          timesheet: '/business/schedule/timesheets',
          leave: '/business/leaves'
        }

        if (
          path.includes('/business/people-v2') ||
          path.includes('/business/settings-v2') ||
          path.includes('/business/documents')
        ) {
          return true
        }

        return Object.keys(paths).some((key) => paths[key] === path && flags && flags[key])
      }

      $rootScope.openSetupBusinessModal = function () {
        Analytics.logEvent('Checklist Click: Setup Company')
        $ngRedux.dispatch(setModals([{ kind: 'SetupBusiness', options: { overlay: true } }]))
      }

      $rootScope.openReferFriends = function () {
        $ngRedux.dispatch(setModals([{ kind: 'ReferFriends', options: { overlay: true } }]))
        Analytics.logEvent('Refer a friend - Clicked Refer & Earn')
      }

      $scope.onShowSwitchSubRequired = () => {
        Service.infoPopup({
          title: 'Subscription Switch Required',
          contents:
            "To enjoy the benefits of unlimited Pay runs on both Mobile and Web, please switch your subscription to the Web platform. Don't forget to cancel your current subscription on Mobile.",
          okCallback: () => {
            $ngRedux.dispatch(
              setModalSubscription({
                source: 'businessBanner'
              })
            )
          }
        })
      }

      $scope.hideNonPayment = () => {
        $('#NonPayment').slideUp(350)
        setTimeout(() => {
          $ngRedux.dispatch(hideSubBanner())
        }, 500)
      }
      $scope.hideBannerExpire = () => {
        $('#bannerExpire').slideUp(350)
      }

      $scope.amplitudeEvent = (triggerPoint) => {
        Analytics.logEvent('click the trigger points', {
          trigger_point: triggerPoint,
          company_id: $rootScope.company._id
        })
      }
      $scope.showSubscriptionModal = () => {
        const subscription = $ngRedux.getState().subscription
        const subscriptionStatus = $ngRedux.getState().app.subscriptionStatus
        const hasValidMobileSubscription = isValidMobileSubscription(subscriptionStatus)
        if (hasValidMobileSubscription) {
          if (!subscriptionStatus.webapp.isValid) {
            $scope.onShowSwitchSubRequired()
            return
          } else {
            Service.alert(
              "Don't forget to cancel on Mobile",
              'Ensure you cancel your subscription on the Mobile app to avoid any duplicate charges. Please refer to the guideline below for assistance',
              'success'
            )
          }
        }
        if (
          $rootScope.gbFeatures.delayed_tokenisation &&
          subscription.errorCode !== 'SUBSCRIPTION_TRIAL_ENDED' &&
          $rootScope.company.allowTrial
        ) {
          $ngRedux.dispatch(setModals([{ kind: 'FreeTrial' }]))
        } else {
          $scope.amplitudeEvent('SuperStream')
          $ngRedux.dispatch(
            setModalSubscription({
              source: 'SuperStream'
            })
          )
        }
      }

      $scope.checkReferalEnabled = () => {
        const wholeSubs = $ngRedux.getState().app.subscriptionStatus
        if (
          $rootScope?.gbFeatures?.refer_and_earn &&
          !$rootScope?.isAgent &&
          !$scope?.subscription?.subscriptionError &&
          (wholeSubs?.webapp?.trial?.end === undefined || moment().isSameOrAfter(wholeSubs?.webapp?.trial?.end))
        ) {
          return true
        } else return false
      }

      $scope.subscriptionModal = function () {
        if (!$window.localStorage.url_client_pay) return
        $ngRedux.dispatch(
          setModalSubscription({
            source: 'businessClientPay'
          })
        )
      }
      $scope.subscriptionModal()

      $scope.notificationCount = 0
      $scope.notificationState = {
        open: false,
        service: Http,
        updateCount: (newCount) => {
          $scope.notificationCount = newCount
        },
        showV2Notification: false
      }

      // Callback is used for operations to perform after modal actions are finished
      // Which include submitting to STP for example
      $rootScope.stpTransitionState = {
        callback: () => {}
      }

      $scope.toggleSideBar = function () {
        $ngRedux.dispatch(setShowHelpMenu(!$rootScope.showHelpMenu))
      }

      $scope.openLeaveModal = function () {
        Service.modal('openLeaveModal.html', [
          '$scope',
          '$uibModalInstance',
          function ($scope, $uibModalInstance) {
            // $scope.pendingLeaveList = pendingLeaveList
            $scope.cancel = function () {
              $uibModalInstance.dismiss()
            }

            $scope.updateLeave = function (leave, option) {
              $scope.loading = true
              const leaveCopy = { ...leave }
              leaveCopy.s = option ? 'a' : 'r'
              leaveCopy.handledAt = new Date()
              leaveCopy.isDismissed = false
              Http(
                {
                  method: 'post',
                  api: '/v1/leave/update/' + leave._id,
                  data: {
                    leave: leaveCopy
                  },
                  disableErrorMsg: true
                },
                () => {
                  leave.s = leaveCopy.s
                  $scope.loading = false

                  $rootScope.pendingLeaveList = $scope.pendingLeaveList.filter((leave) => {
                    return leave.s == 'p'
                  })
                  if ($rootScope.pendingLeaveList.length == 0) {
                    $uibModalInstance.dismiss()
                  }
                },
                (err) => {
                  if (err.error) {
                    Service.alert('Alert', err.error, 'error')
                  } else {
                    Service.alert(
                      'Alert',
                      'Sorry, there was a problem processing your request. Please try again.',
                      'error'
                    )
                  }
                  $scope.loading = false
                }
              )
            }

            $scope.leaveOption = function (leave, value) {
              $scope.updateLeave(leave, value)
            }

            $scope.getRange = function (startDate, endDate) {
              var formattedStartDate = moment(startDate).format('D MMM YYYY')
              var formattedEndDate = moment(endDate).format('D MMM YYYY')

              if (formattedEndDate == formattedStartDate) {
                return formattedStartDate
              } else {
                return formattedStartDate + ' ~ ' + formattedEndDate
              }
            }
          }
        ])
      }

      $scope.isInitialDataFetching = false

      getInitialData = ngGetInitialData({
        $ngRedux,
        $rootScope,
        Service,
        $window,
        $state,
        Analytics,
        HttpPromiseAndLocalStorage,
        successCallback: () => {
          // reload once after initial data is loaded to update states (only for '/business/superstream/ready-to-submit' page)
          if (!initialDataLoaded && $state.current.name === 'business.superStream.readyToSubmit') {
            initialDataLoaded = true
            $state.go($state.current, {}, { reload: true })
          }

          $scope.isInitialDataFetching = false
        }
      })

      const getV2Notifications = () => {
        const revampView = $scope?.appState?.gbFeatures?.ui_revamp_display
        if (!revampView) return false

        Http(
          {
            method: 'get',
            api: '/v1/rosters/employer-notifications',
            disableErrorMsg: true
          },
          (res) => {
            $state.notificationCount = res.notifications.length + res.requests.length
          }
        )

        Http(
          {
            method: 'get',
            api: '/v1/notifications/reminders',
            disableErrorMsg: true
          },
          (res) => {
            $state.notificationCount += res.reminders.length
          }
        )
      }

      getInitialDataByYear = ngGetInitialData({
        $ngRedux,
        $rootScope,
        Service,
        $window,
        $state,
        Analytics,
        HttpPromiseAndLocalStorage,
        successCallback: () => {
          // reload once after initial data is loaded to update states (only for '/business/superstream/ready-to-submit' page)
          if (!initialDataLoaded && $state.current.name === 'business.superStream.readyToSubmit') {
            initialDataLoaded = true
            $state.go($state.current, {}, { reload: true })
          }
        },
        newFilterApi: true,
        year: $ngRedux.getState()?.app?.taxYear
      })

      $scope.getInitialData = () => {
        $scope.isInitialDataFetching = true
        getInitialData()
      }

      $scope.openGetStartedModal = function () {
        Service.modal(
          'getStartedModal.html',
          [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              $scope.cancel = function () {
                $uibModalInstance.dismiss()
              }
              $scope.companyData = $rootScope.company ? angular.copy($rootScope.company) : {}
              $scope.companyData.stp = $scope.companyData.stp || {
                bn: '1',
                e: $scope.companyData.e || $rootScope.user.e
              }

              $scope.currentStage = 1
              new WOW().init()
              $scope.softwareId = Service.getSSID($rootScope.company.si)
              $scope.searchMouseOver = function () {
                // animation error를 수정하기 위해서
                $('#search-abn-input').focus()
                $timeout(() => {
                  $scope.mouseOver = true
                }, 1000)
              }

              $timeout(() => {
                $scope.mouseOver = true
              }, 3000)

              $scope.searchCompanyName = function (searchValue) {
                // check if searchValue is ABN or Company name
                if (Service.isABN(searchValue)) {
                  // search ABN
                  $scope.searchLoading = true
                  Http(
                    {
                      method: 'get',
                      api: '/v1/check-abn/' + searchValue,
                      disableErrorMsg: true,
                      forceSend: true
                    },
                    (res) => {
                      if (!res.Abn || res.Message) {
                        Service.alert(null, res.Message)
                        return false
                      }
                      $scope.searchLoading = false

                      $scope.companyData.abn = searchValue
                      $scope.companyData.c = res.EntityName
                      $scope.companyData.a_p = res.AddressPostcode
                      $scope.companyData.a_s = res.AddressState
                      $scope.completeStage(null, 2)
                    },
                    (err) => {
                      $scope.searchLoading = false
                      $scope.companyData.abn = searchValue
                      $scope.completeStage(null, 2)
                    }
                  )
                } else {
                  // company name
                  $scope.searchLoading = true
                  Http(
                    {
                      method: 'post',
                      api: '/v1/search-abn/',
                      data: {
                        searchName: searchValue
                      }
                    },
                    (res) => {
                      $scope.searchLoading = false
                      if (res.Names && res.Names.length > 0) {
                        $scope.searchedCompanyList = res.Names.slice(0, 9)
                      } else {
                        Service.alert('Nothing found', 'Your search did not match any company name.', 'error')
                      }
                      $scope.searchLoading = false
                    },
                    () => {
                      $scope.searchLoading = false
                      Service.confirm({
                        title: 'ABN Search not available',
                        contents:
                          'The Australian Business Register (ABR) is currently undergoing maintenance. Would you like to enter ABN number manually?',
                        confirmText: 'Yes',
                        confirmCallback: function () {
                          $scope.completeStage(null, 1.5)
                        },
                        cancelCallback: () => {}
                      })
                    }
                  )
                }
              }

              $scope.selectCompany = function (searchedCompany) {
                $scope.companyData.abn = searchedCompany.Abn
                $scope.companyData.c = searchedCompany.Name
                if (searchedCompany.State) {
                  $scope.companyData.a_p = searchedCompany.Postcode
                  $scope.companyData.a_s = searchedCompany.State
                }
                $scope.completeStage(null, 2)
              }

              $scope.completeStage = function (currentStage, nextStage) {
                var updateServer = false
                var invalid = false
                $scope.companyError = {}

                switch (nextStage) {
                  case 2:
                    Analytics.logEvent('Enter Company Name And ABN')
                    break
                  case 3:
                    Analytics.logEvent('Enter Address')
                    break
                  case 4:
                    Analytics.logEvent('Enter Number Of Work Hours', {
                      work_hours: $scope.companyData.wh
                    })
                    break
                  case 5:
                    Analytics.logEvent('Select Pay Period', {
                      pay_period: ($scope.companyData.pp = 'w'
                        ? 'weekly'
                        : ($scope.companyData.pp = 'f'
                            ? 'fortnightly'
                            : ($scope.companyData.pp = 'm'
                                ? 'monthly'
                                : ($scope.companyData.pp = 'q' ? 'Quarterly' : null))))
                    })
                    break
                  case 6:
                    Analytics.logEvent('Onboarding Success', {
                      stp_enabled: !!$scope.companyData.stp.on
                    })
                    if ($scope.companyData.stp.on) {
                      Analytics.logEvent('Enable STP')
                    }
                    break
                  default:
                    break
                }
                switch (currentStage) {
                  case 1.5:
                    if (!$scope.companyData.abn || !Service.isABN($scope.companyData.abn)) {
                      $scope.companyError.abn = true
                      invalid = true
                    }
                    if (invalid) {
                      return false
                    }
                    break
                  case 2:
                    if (!$scope.companyData.a_1) {
                      $scope.companyError.a_1 = true
                      invalid = true
                    }
                    if (!$scope.companyData.a_s) {
                      $scope.companyError.a_s = true
                      invalid = true
                    }
                    if (!$scope.companyData.a_p) {
                      $scope.companyError.a_p = true
                      invalid = true
                    }
                    if (invalid) {
                      return false
                    }
                    break
                  case 3.1:
                    if (isNaN($scope.companyData.wh)) {
                      $scope.companyError.wh = true
                      invalid = true
                    }
                    if (invalid) {
                      return false
                    }
                    break
                  case 4:
                    // set up start Date
                    if (!$scope.companyData.sd) {
                      switch ($scope.companyData.pp) {
                        case 'w':
                          $scope.companyData.sd = moment().subtract(1, 'weeks').utc().startOf('isoWeek')._d
                          break
                        case 'f':
                          // 지난주 월요일부터 토요일까지 세팅
                          $scope.companyData.sd = moment().subtract(2, 'weeks').utc().startOf('isoWeek')._d
                          break
                        case 'q':
                          // 지난주 월요일부터 토요일까지 세팅
                          $scope.companyData.sd = moment().subtract(3, 'months').date(1).utc().startOf('day')._d
                          break
                        default:
                          $scope.companyData.sd = moment().subtract(1, 'months').date(1).utc().startOf('day')._d
                          break
                      }
                    }
                    break
                  case 5:
                    updateServer = true
                    break
                  case 5.1:
                    if (!$scope.companyData.stp.cn) {
                      $scope.companyError.stp_cn = true
                      invalid = true
                    }

                    if (!$scope.companyData.stp.ph) {
                      $scope.companyError.stp_ph = true
                      invalid = true
                    }
                    if (invalid) {
                      return false
                    }
                    $scope.companyData.stp.on = true
                    updateServer = true
                    break
                  default:
                    break
                }

                if (updateServer) {
                  $scope.currentStage = 0
                  $timeout(() => {
                    $scope.currentStage = 'loading'
                    CompanyAction.update(
                      $scope.companyData,
                      () => {
                        $scope.currentStage = 6
                      },
                      () => {
                        $scope.currentStage = currentStage
                      }
                    )
                  }, 300)
                } else {
                  $scope.currentStage = 0
                  $timeout(() => {
                    $scope.currentStage = nextStage
                  }, 300)
                }
              }

              $scope.goBack = function () {
                var previousStep = Math.ceil($scope.currentStage) - 1
                $scope.currentStage = 0
                $timeout(function () {
                  $scope.$evalAsync(function () {
                    $scope.currentStage = previousStep
                  })
                }, 400)
                $('.wow').removeClass('animated')
                $('.wow').removeAttr('style')
                new WOW().init()
              }

              $scope.sendSoftwareId = function () {
                $scope.loading = true
                Http(
                  {
                    method: 'post',
                    api: '/v1/send-stp-info/'
                  },
                  () => {
                    Service.alert('Email sent', 'Email has been sent successfully', 'success')
                    $timeout(() => {
                      $scope.loading = false
                    }, 500)
                    Analytics.logEvent('Send STP Info Via Email')
                  },
                  () => {
                    $scope.loading = false
                  }
                )
              }
            }
          ],
          { size: 'full' }
        )
      }

      // $scope.userLoad();
      $rootScope.$on('reloadUserData', function () {
        $scope.userLoad()
      })

      // $scope.logout= function(){
      // 	UserAuthFactory.logout();
      // 	// AuthenticationFactory.logout().then(function(){
      // 	//   $state.go('home');
      // 	// });
      // };
      // $scope.parent = {};
      // $scope.parent.openEditOvertimeCategoryModal= function(){
      $scope.openEditOvertimeCategoryModal = function () {
        if (!$rootScope.company || !$rootScope.company.ovc) {
          Service.alert('', 'Please complete your company setup!', 'error')
          return false
        }
        Service.modal(
          'editOvertimeCategoryModal.html',
          [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              $scope.cancel = function () {
                $uibModalInstance.dismiss()
              }

              $scope.overtimes = angular.copy($rootScope.company.ovc)

              for (let index = 0; index < $scope.overtimes.length; index++) {
                $scope.overtimes[index].or = $scope.overtimes[index].or * 100
              }

              $scope.addNewOvertime = function () {
                $scope.overtimes.push({
                  n: null,
                  or: 0
                })
              }

              $scope.deleteOvertime = function (index) {
                $scope.overtimes.splice(index, 1)
              }

              $scope.save = function () {
                var overtime_temp = []
                for (let index = 0; index < $scope.overtimes.length; index++) {
                  const overtime = $scope.overtimes[index]
                  if (overtime.or || index == 0) {
                    overtime_temp.push({
                      n: overtime.n,
                      or: overtime.or / 100
                    })
                  }
                }
                $scope.loading = true
                Http(
                  {
                    method: 'post',
                    api: '/v1/company/update',
                    data: {
                      company: {
                        ovc: overtime_temp
                      }
                    }
                  },
                  (res) => {
                    $rootScope.company = res.company
                    $scope.loading = false
                    $scope.cancel()
                  },
                  () => {
                    $scope.loading = false
                  }
                )
              }
            }
          ],
          { size: 'md' }
        )
      }

      $scope.onRouteToBilling = () => {
        $state.go('business.settings', {
          open: 'billing'
        })
      }

      $scope.logout = function () {
        Analytics.logEvent('SettingPage Logout Clicked')
        Service.hideLoading()
        $window.localStorage.removeItem('url_client_pay')
        $window.localStorage.removeItem('pr_industry')
        Logout()
        window.Sentry?.setUser(null)
        if ($scope.appState.isTsrOnly) {
          $state.go('login')
        } else {
          $state.go('home.main')
        }
      }

      $scope.goToSubscription = function () {
        $state.go('update-subscription')
      }
      $scope.openNotifications = () => {
        const revampView = $scope?.appState?.gbFeatures?.ui_revamp_display
        if (revampView) {
          if (!$rootScope.user.e && !$rootScope.company.e) return
          RefreshTokenNoDebounce((tokenResponse, error) => {
            const errorMessage = error?.data?.message
            if (errorMessage === 'Token Expired') {
              Service.alert('Alert', errorMessage, 'error')
              Logout()
              $state.go('login', {
                redirect: window.location.pathname
              })
            } else {
              const { token, expires } = tokenResponse || {}
              const queryParams = new URLSearchParams({
                isIframe: true,
                t: token,
                e: expires,
                clientId: $rootScope.company._id
              })
              const notificationRevampIframe = document.getElementById('notification-v2')

              notificationRevampIframe.src = `${process.env.DEV_V2}/notification-page?${queryParams}`
              notificationRevampIframe.onload = () => {
                $scope.isLoading = false
                $rootScope.$apply()
              }
            }
          })
          $scope.notificationState.showV2Notification = true
        }

        $scope.notificationState.open = true
      }

      // has token, get init data after setting up
      if ($stateParams.t) {
        UserAuthFactory.loginSuccess({
          token: {
            token: $stateParams.t,
            expires: parseInt($stateParams.e),
            user: $stateParams.u
          }
        }).then(() => {
          $scope.getInitialData()
          // don't redirect when called on settingCtrl
          if (!$stateParams.open) {
            // GTM
            if ($stateParams.newUser === 'true' && dataLayer && typeof dataLayer.push === 'function') {
              dataLayer.push({
                event: 'SignupSuccess',
                source: $stateParams.s
              })
            }

            if (!$stateParams.noRedirection) $state.go('business.run.overview', {}, { inherit: false })
          }
        })
      } else {
        $scope.getInitialData()
      }

      $scope.resendVerification = function () {
        // 어느 도메인으로 부터 reset request 가 나왔는지 알기 위해
        var hostArray = window.location.hostname.split('.')

        Http(
          {
            method: 'post',
            api: '/v1/resend-verification',
            data: {
              verificationOnly: $rootScope.user.wp === 'm',
              url: hostArray[hostArray.length - 2] === 'payrolls' ? hostArray[hostArray.length - 3] : null
            }
          },
          (res) => {
            Service.alert('Success!', 'Verification has been sent!', 'success')
          },
          () => {}
        )
      }

      $scope.slideUpMenuOnMobile = function () {
        if ($(document).width() < 768) {
          $timeout(() => {
            $('.menu-and-user').slideUp()
          }, 100)
        }
      }

      $scope.openInviteAgentModal = () => {
        $scope.amplitudeEvent('link-agent')
        Service.modal(
          'inviteAgentModal.html',
          [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              $scope.cancel = function () {
                $uibModalInstance.dismiss()
              }

              $scope.sendInvitation = function () {
                $scope.emailError = false
                if (!$scope.agentEmail || !Service.validateEmail($scope.agentEmail)) {
                  $scope.emailError = true
                  return false
                }
                $scope.loading = true
                Http(
                  {
                    method: 'post',
                    api: '/v1/invite-agent/',
                    data: {
                      agentEmail: $scope.agentEmail
                    }
                  },
                  (res) => {
                    $scope.loading = false
                    Service.alert('Success!', 'Invitation has been sent successfully!', 'success')
                    $scope.cancel()
                    Analytics.logEvent('Invite Agent')
                  },
                  () => {
                    $scope.loading = false
                  }
                )
              }
            }
          ],
          { size: 'sm' }
        )
      }

      $scope.isLoading = false
      $scope.onOptOutPeopleRevamp = async () => {
        $scope.isLoading = true
        $scope.$apply()
        const ENDPOINT = process.env.API_ENDPOINT
        await postData({
          url: `${ENDPOINT}/v1/company/update`,
          data: {
            company: {
              optInPeopleRevamp: false
            }
          }
        })
        $scope.getInitialData()
        $state.go('business.people')
        $scope.isLoading = false
      }

      $scope.onOptOutAIRoster = async (newOptInValue) => {
        const ENDPOINT = process.env.API_ENDPOINT
        $scope.isLoading = true
        await postData({
          url: `${ENDPOINT}/v1/company/update`,
          data: {
            company: {
              optInAIRoster: newOptInValue
            }
          }
        })

        $scope.getInitialData()
        $state.go('business.rosters')
        $scope.isLoading = false
      }

      $scope.onOptOutReportsRevamp = async () => {
        $scope.isLoading = true
        $scope.$apply()
        const ENDPOINT = process.env.API_ENDPOINT
        await postData({
          url: `${ENDPOINT}/v1/company/update`,
          data: {
            company: {
              optInReportRevamp: false
            }
          }
        })
        $scope.getInitialData()
        $state.go('business.reports')
        $scope.isLoading = false
      }

      $scope.onOptOutSuperRevamp = async () => {
        $scope.isLoading = true
        $scope.$apply()
        const ENDPOINT = process.env.API_ENDPOINT
        await postData({
          url: `${ENDPOINT}/v1/company/update`,
          data: {
            company: {
              optInSuperRevamp: false
            }
          }
        })
        $scope.getInitialData()
        $state.go('business.superStream.readyToSubmit')
        $scope.isLoading = false
      }

      openSuperSettingModal = function () {
        Service.modal('superSettingModal.html', [
          '$scope',
          '$uibModalInstance',
          async function ($scope, $uibModalInstance) {
            $scope.cancel = function () {
              $uibModalInstance.dismiss()
            }

            if (!$rootScope.company?.superStream?.beamAcceptDate) {
              var ret = await Http2({
                method: 'get',
                api: '/v1/super/check-beamconnect',
                data: {
                  companyId: $rootScope.company?._id
                }
              })
              $rootScope.company.superStream.connectedDate = ret.acceptDate
            }

            if ($rootScope.company.superStream && $rootScope.company.superStream.refundDetails) {
              $scope.refundBankAccount = angular.copy($rootScope.company.superStream.refundDetails)
            } else {
              $scope.refundBankAccount = null
            }
            $scope.saveDetails = () => {
              $scope.refundBankError = {}
              var error = false
              if (!$scope.refundBankAccount.bsb || !/^[0-9]{6}$/i.test($scope.refundBankAccount.bsb)) {
                // if(!$scope.refundBankAccount.bsb || !$scope.refundBankAccount.bsb.match(^[0-9-]{6}$)){
                $scope.refundBankError.bsb = true
                error = true
              }
              if (
                !$scope.refundBankAccount.accountNumber ||
                !/^[0-9-]{1,9}$/i.test($scope.refundBankAccount.accountNumber)
              ) {
                $scope.refundBankError.accountNumber = true
                error = true
              }
              if (!$scope.refundBankAccount.accountName) {
                $scope.refundBankError.accountName = true
                error = true
              }

              if (error) {
                return false
              }

              CompanyAction.update(
                { 'superStream.refundDetails': $scope.refundBankAccount },
                function () {
                  // success callback
                  $scope.loading = false
                  $scope.cancel()
                },
                function () {
                  // error callback
                  $scope.loading = false
                }
              )
            }

            $scope.connectBeam = () => {
              const subscription = $ngRedux.getState()?.app?.subscriptionStatus
              console.log(subscription)
              if (!subscription.webapp.isValid) {
                $ngRedux.dispatch(
                  setModalSubscription({
                    source: 'connectBeam'
                  })
                )
              } else updateParticipant()
            }

            $scope.disconnectBeam = () => {
              Service.confirm({
                title: 'Confirm disconnect from Beam?',
                contents: `You will not be able to enjoy the convenience of paying superannuation from Payroller any more.`,
                confirmText: 'Cancel',
                cancelText: 'Disconnect from Beam',
                confirmCallback: function () {},
                cancelCallback: () => {
                  $scope.cancel()
                  CompanyAction.update(
                    {
                      'superStream.connectedDate': null,
                      'superStream.refundDetails': null
                    },
                    () => {
                      // success callback
                      Analytics.logEvent('Superstream disconnect')
                    },
                    () => {
                      // error callback
                    }
                  )
                }
              })
            }
          }
        ])
      }
      $scope.openSuperSettingModal = openSuperSettingModal

      updateParticipant = () => {
        Http(
          {
            method: 'get',
            api: '/v1/super/connect-link',
            disableErrorMsg: true
          },
          (res) => {
            if (res.token) {
              window.location.replace(BEAM_URL + '/#access_token=' + res.token)
            } else {
              Service.alert()
            }
          },
          () => {
            Service.alert()
          }
        )
      }

      showGoogleReview = () => {
        // "Enjoy Payroller?" popup will show only once in the user lifetime.
        if (
          $rootScope.user &&
          $rootScope.user.e &&
          $rootScope.payRuns.length >= PAYRUN_LENGTH_FOR_GOOGLE_REVIEW &&
          !$rootScope.user.rt_w
        ) {
          // show write review
          Service.confirm({
            title: 'Enjoying Payroller?',
            contents: 'Do you like Payroller as much as we do?',
            confirmText: 'Yes!',
            confirmCallback: function () {
              Service.confirm({
                title: 'Would you like to rate us on Google review?',
                contents: 'Give us a 5 star rating',
                confirmText: 'Ok, sure!',
                confirmCallback: function () {
                  window.open(
                    'https://search.google.com/local/writereview?placeid=ChIJUY0ODeO7EmsR0VNXePqVtjU',
                    '_blank'
                  )
                },
                secondText: "Don't ask me again",
                removeCancel: true
              })
            },
            removeCancel: true,
            secondText: 'Not really',
            secondCallback: () => {
              Service.confirm({
                title: 'Would you mind giving us some feedback?',
                contents: '',
                confirmText: 'Ok, sure!',
                confirmCallback: function () {
                  window.open('https://payroller.nolt.io/', '_blank')
                },
                secondText: 'No, thanks',
                removeCancel: true
              })
            }
          })

          // update user status (DB)
          Http(
            {
              method: 'post',
              api: '/v1/user/update',
              data: {
                user: { rt_w: true }
              },
              disableErrorMsg: true
            },
            (res) => {
              $rootScope.user = res.user
            },
            () => {}
          )
        }
      }

      getV2Notifications()
      openPayrunAutomationOptInModal = () => {
        if (!$rootScope?.gbFeatures?.opt_in_pay_run_automation) return showGoogleReview() // growthbook is on
        if ($scope.company?.optInPayRunAutomation) return showGoogleReview() // pay run automation is already opted in
        if ($scope.company?.payRunAutomation.modalSeenAt) return showGoogleReview() // never opted in or never dismissed the modal

        const payPeriod = $scope.company?.pp
        if (!payPeriod) return showGoogleReview() // pay period is set

        const lastEndDate = $scope.payRuns
          .filter((payRun) => ['s', 'c'].includes(payRun.s))
          .reduce((l, c) => (c.ed.localeCompare(l?.ed ?? '') > 0 ? c : l), undefined)?.ed
        const startDate = momentTimezone(lastEndDate).add(1, 'days').toDate()
        let endDate
        switch (payPeriod) {
          case 'w':
            endDate = momentTimezone(startDate).add(6, 'days').toDate()
            break
          case 'f':
            endDate = momentTimezone(startDate).add(13, 'days').toDate()
            break
          case 'q':
            endDate = momentTimezone(startDate).add(3, 'months').subtract(3, 'days').toDate()
            break
          case 'm':
          default:
            endDate = momentTimezone(startDate).add(1, 'months').subtract(1, 'days').toDate()
            break
        }
        const nextRunAt = momentTimezone(endDate).subtract(1, 'days').tz('Australia/Sydney').endOf('day') // 2024-12-30T12:59:59 in UTC + 0
        if (momentTimezone().isAfter(nextRunAt)) return showGoogleReview() // backfill

        Analytics.logEvent('Payrun Automation Opt-in Modal Viewed')
        $ngRedux.dispatch(
          setModals([
            {
              kind: 'EnablePayrunAutomationModal',
              channel: 'local',
              options: {
                data: { payPeriod, Http2, refetchInitial: () => $scope.getInitialData() },
                overlay: true
              }
            }
          ])
        )
      }
    }
  )

angular.module('starter.business.controllers').filter('money', function ($filter, GLOBALS) {
  return function (input) {
    if (GLOBALS.ROUNDING_DEBUG) {
      return $filter('currency')(input, '$', 5)
    } else {
      return $filter('currency')(input)
    }
  }
})
