const homeReroute = ($state, $stateParams) => {
  const isTsrOnly = process.env.APP === 'people'
  if (!isTsrOnly) {
    $state.go('business.run.overview', {
      ...($stateParams?.agentId && { agentId: $stateParams.agentId }),
      ...($stateParams?.activePayrun && { activePayrun: $stateParams.activePayrun })
    })
  } else {
    if ($state?.user && $state?.user?.userType === 'employee') {
      $state.go('business.clock')
    } else {
      $state.go('business.rosters')
    }
  }
}

export default homeReroute
