import homeReroute from '../../../utils/homeReroute'
import { eventNames } from '@bookipi/utils'

angular
  .module('starter.auth.controllers', [])
  .controller('AuthCtrl', function ($scope, $rootScope, Http) {
    $scope.currentStep = 1
  })
  .controller(
    'LogInCtrl',
    function (
      $location,
      $stateParams,
      $rootScope,
      $scope,
      $window,
      Service,
      UserAuthFactory,
      $state,
      GLOBALS,
      Analytics,
      AuthenticationFactory
    ) {
      $scope.stateParams = $stateParams
      $scope.isTsrOnly = process.env.APP === 'people'
      if (GLOBALS.GUI_DEBUG) {
        $scope.environment = 'DEBUG'
      } else if (!GLOBALS.GUI_DEBUG && Math.random() >= 0.5) {
        $scope.environment = 'ModelA'
      } else {
        $scope.environment = 'ModelB'
      }

      $scope.auth = {
        username: '',
        password: ''
      }
      if (GLOBALS.user && GLOBALS.pass) {
        $scope.auth.username = GLOBALS.user
        $scope.auth.password = GLOBALS.pass
      }
      Analytics.logEvent('Login Started')

      // Redirect the user to the active payrun if they are already logged in
      if (AuthenticationFactory.isLogged && $stateParams.activePayrun) {
        $state.go('business.run.overview', {
          activePayrun: $stateParams.activePayrun
        })
      }

      const doLogIn = (googleEmail, googleTokenId, passedAuth, callback) => {
        var username = $scope.auth.username || googleEmail || passedAuth?.email
        var password = $scope.auth.password || passedAuth?.password
        $rootScope.username = username
        Analytics.logEvent(eventNames.AuthLoginCompleted)

        let agentId = $stateParams.agentId
        if ((username !== undefined && password !== undefined) || googleTokenId) {
          Service.showLoading()

          UserAuthFactory.login(username, password, agentId, googleTokenId).then(
            function (responseData) {
              $scope.auth = {}

              $window.localStorage.userType = responseData?.user.userType

              // email is not verified for employeer
              if (!responseData.user.vf && !$scope.isTsrOnly && responseData?.user.userType == 'employee') {
                $state.go('signup', { variant: 'verification', email: username, q1: responseData.user.q1 })
                return
              }

              $window.localStorage.removeItem('pr_industry')

              if (responseData.token) {
                Analytics.logEvent('Login Success')

                if ($scope.isTsrOnly && responseData?.user.userType === 'employee') {
                  homeReroute({ ...$state, ...responseData }, $stateParams)
                  callback?.(responseData)
                }

                if (!responseData.company || !responseData.company.ag) {
                  delete $window.localStorage.pr_agent_id
                  $rootScope.isAgent = false

                  if (
                    $stateParams.redirect &&
                    $stateParams.redirect.indexOf('login') === -1 &&
                    $stateParams.redirect.indexOf('try-demo') === -1
                  ) {
                    $location.path($stateParams.redirect).search($stateParams.search)
                  } else {
                    homeReroute({ ...$state, ...responseData }, $stateParams)
                  }
                } else {
                  $window.localStorage.pr_agent_id = responseData.company._id
                  $rootScope.isAgent = true
                  if (
                    $stateParams.redirect &&
                    $stateParams.redirect.indexOf('login') === -1 &&
                    $stateParams.redirect.indexOf('try-demo') === -1
                  ) {
                    $location.path($stateParams.redirect).search($stateParams.search)
                  } else {
                    $state.go('agent.clients')
                  }
                }
              } else {
                if (responseData.tfaMethod === 'email') {
                  $rootScope.tfa = responseData
                  $rootScope.userId = responseData.ui
                  $state.go('verifyViaEmail', {
                    agentId: $stateParams.agentId,
                    search: $stateParams.search,
                    redirect: $stateParams.redirect,
                    activePayrun: $stateParams.activePayrun
                  })
                } else if (responseData.tempSecret) {
                  $rootScope.tfa = responseData
                  $state.go('setupTfa', {
                    redirect: $stateParams.redirect,
                    search: $stateParams.search,
                    agentId: $stateParams.agentId,
                    activePayrun: $stateParams.activePayrun
                  })
                  // $state.go("verifyTfa",{userId : responseData.user._id});
                } else {
                  $rootScope.tfa = responseData
                  $rootScope.userId = responseData.ui
                  $rootScope.login_as_admin = !!responseData.admin
                  $state.go('verifyTfa', {
                    redirect: $stateParams.redirect,
                    search: $stateParams.search,
                    agentId: $stateParams.agentId,
                    activePayrun: $stateParams.activePayrun
                  })
                  // $state.go("setupTfa",{userId : responseData.user._id});
                }
              }

              // if (responseData && responseData.company){
              // 	$rootScope.user = responseData.user;

              // 	if (!responseData.company.ag){
              // 		// $state.go("business.run.overview");
              // 	} else {
              // 		$window.localStorage.pr_agent_id = responseData.company._id;
              // 		$rootScope.isAgent = true;
              // 		// $state.go("agent.clients");
              // 	}

              // } else {
              // 	// $state.go("business.getStarted");
              // }
              callback?.(responseData)
            },
            function (message) {
              Analytics.logEvent('Login Error')
              Service.hideLoading()

              Service.alert('Login failed!', message, 'error')
              callback?.(message)
            }
          )
        } else {
          Service.hideLoading()

          Service.alert('Invalid credentials', '', 'error')
        }
      }

      const goToForgotPassword = () => {
        $state.go('forgotPassword')
        Analytics.logEvent(eventNames.AuthForgotPasswordVisited)
      }

      $scope.doLogIn = doLogIn
      $scope.reactState = {
        doLogIn,
        goToForgotPassword,
        Service,
        go: $state.go,
        $stateParams,
        analytics: Analytics
      }

      $scope.changeLanguage = function (newLang) {
        Service.changeLanguage(newLang)
      }
      if ($location.search() && $location.search().lang) {
        var newLang = $location.search().lang
        if (
          newLang == 'en' ||
          newLang == 'fr' ||
          newLang == 'it' ||
          newLang == 'pt' ||
          newLang == 'es' ||
          newLang == 'de'
        ) {
          Service.changeLanguage(newLang)
        }
      }
    }
  )
  .controller(
    'SignUpCtrl',
    function (
      $rootScope,
      $scope,
      UserAuthFactory,
      $state,
      AuthenticationFactory,
      $window,
      Service,
      $stateParams,
      $location
    ) {
      // $scope.confirmUsername='';
      // $scope.authSignup = {
      // 	companyname: '',
      // 	username: '',
      //   	password: ''
      // };

      // $scope.signupWithGoogle = function () {
      //   UserAuthFactory.googleRegister();
      // };

      $scope.doSignUp = function () {
        Service.showLoading('Signing in....')
        UserAuthFactory.register($scope.authSignup).then(
          function (data) {
            // user 정보를 가져온다.
            // $rootScope.$broadcast('reloadUserData');
            $scope.authSignup = {}
            // $state.go('admin.profile');
          },
          function (errMsg) {
            Service.hideLoading()

            Service.alert('Register failed!', errMsg, 'error')
          }
        )
      }

      $scope.changeLanguage = function (newLang) {
        Service.changeLanguage(newLang)
      }
    }
  )
