"use strict";
exports.__esModule = true;
exports.VariablePayRateInitiatedAt = exports.EmployeeRosterShiftViewedType = exports.UnavailabilityTriggeredFromLocations = exports.UserActivity = exports.eventNames = void 0;
exports.eventNames = {
    AppOpen: 'Application Opened',
    AuthLoginCompleted: 'Auth Login Completed',
    AuthForgotPasswordVisited: 'Auth Forgot Password Visited',
    AuthSignupVisited: 'Auth Signup Visited',
    AuthResetPasswordSent: 'Auth Reset Password Sent',
    AuthForPassLoginRedirect: 'Auth ForPass Login Redirected',
    EmployeeAdditionInitiated: 'Employee Addition Initiated',
    EmployeeAdditionCancelled: 'Employee Addition Cancelled',
    EmployeeAdditionStep1Completed: 'Employee details',
    EmployeeAdditionStep2Completed: 'Employment details',
    EmployeeAdditionStep3Completed: 'Send Employee invitation',
    AuthSignupButtonClicked: 'Auth Signup Button Clicked',
    AuthEmailVerificationResent: 'Auth Email Verification Resent',
    BPOnboardStep1Completed: 'BookipiPeople Onboard Step 1 Completed',
    BPOnboardStep2Completed: 'BookipiPeople Onboard Step 2 Completed',
    RosterView: 'Roster Page Viewed',
    RosterAddShift: 'Roster Shift Initiated',
    RosterShiftCreated: 'Roster Shift Created',
    RosterShiftCanceled: 'Roster Shift Canceled',
    RosterViewShift: 'Roster Shift Viewed',
    RosterViewAllTabViewed: 'Roster View All Tab Viewed',
    RosterShiftEdited: 'Roster Shift Edited',
    RosterEditShift: 'Roster Shift Edit Initiated',
    RosterEditShiftSaved: 'Roster Shift Edit Saved',
    RosterEditShiftCanceled: 'Roster Shift Edit Cancelled',
    RosterDeleteShift: 'Roster Shift Delete Initiated',
    RosterDeleteShiftConfirmed: 'Roster Shift Deleted',
    RosterConflictView: 'Roster Conflict Viewed',
    RosterConflictResolved: 'Roster Conflict Resolved',
    RosterPublish: {
        Cancelled: 'Roster Publish Cancelled',
        PublishInitiated: 'Roster Publish Initiated',
        Step1Completed: 'Roster Publish Step 1 Completed',
        Step1Canceled: 'Roster Publish Step 1 Canceled',
        Step2Completed: 'Roster Publish Step 2 Completed',
        Step2Canceled: 'Roster Publish Step 2 Canceled',
        Step3Completed: 'Roster Published',
        Step3Canceled: 'Roster Publish Step 3 Canceled'
    },
    Leave: {
        PageViewed: 'Leave Page Viewed',
        AdditionInitiated: 'Leave Addition Initiated',
        AdditionCancelled: 'Leave Addition Cancelled',
        Created: 'Leave Created',
        Deleted: 'Leave Deleted',
        Viewed: 'Leave Viewed',
        Cancelled: 'Leave Cancelled',
        Approved: 'Leave Approved',
        Declined: 'Leave Declined'
    },
    TimeOff: {
        PageViewed: 'TimeOff Page Viewed'
    },
    Setting: {
        PageViewed: 'Settings Page Viewed',
        BusinessViewed: 'Settings Business Viewed',
        BusinessEdited: 'Settings Business Edited',
        ChangePasswordInitiated: 'Settings Change Password Initiated',
        ChangePasswordCompleted: 'Settings Change Password Completed',
        ProfileEdited: 'Settings Profile Edited',
        ChangeEmailInitiated: 'Settings Change Email Initiated',
        ChangeEmailCompleted: 'Settings Change Email Completed',
        SupportInitiated: 'Settings Support Initiated',
        FeatureRequestInitiated: 'Feature Request Initiated',
        SettingViewed: 'Settings Business Setting Viewed',
        SettingEdited: 'Settings Business Setting Edited',
        SystemViewed: 'Settings System Viewed',
        SystemEdited: 'Settings System Edited',
        DeleteAccountInitiated: 'Delete Account Initiated',
        DeleteAccountConfirmed: 'Delete Account Confirmed',
        DeleteAccountCompleted: 'Delete Account Completed',
        SuperStreamDisconnect: 'Superstream disconnect',
        OrganisationTab: 'Settings Organisation Tab Clicked',
        PersonalTab: 'Settings Personal Tab Clicked',
        BillingTab: 'Settings Billing Tab Clicked',
        StpTab: 'Settings PayrollSTP Tab Clicked',
        SuperTab: 'Settings Superannuation Tab Clicked',
        LeaveTab: 'Settings Leave Tab Clicked',
        RosterTab: 'Settings Roster Tab Clicked',
        AddonsTab: 'Settings Addons Tab Clicked'
    },
    Auth: {
        SignoutCompleted: 'Auth Signout Completed'
    },
    Timesheet: {
        PageViewed: 'Timesheet Page Viewed',
        AdditionInitiated: 'Timesheet Addition Initiated',
        Created: 'Timesheet Created',
        Viewed: 'Timesheet Viewed',
        EditInitiated: 'Timesheet Edit Initiated',
        Edited: 'Timesheet Edited',
        Approved: 'Timesheet Approved',
        Unapproved: 'Timesheet Unapproved',
        AllApproved: ' Timesheet All Approved',
        Discarded: 'Timesheet Discarded',
        Restored: 'Timesheet Restored',
        PageViewedCancelled: 'View Timesheet Cancelled'
    },
    Employee: {
        ClockInCompleted: 'Clock-In Completed',
        ClockOutCompleted: 'Clock-Out Completed',
        ShiftNotificationAccepted: 'Shift Notification Accepted',
        ShiftNotificationDeclined: 'Shift Notification Declined',
        ShiftNotificationSeeMore: 'Shift Notification See More',
        ShiftNotificationSeeMoreDismissed: 'Shift Notification See More Dismissed',
        SettingsSwitchEmployerInitiated: 'Settings Switch Employer Initiated',
        SettingsSwitchEmployerCompleted: 'Settings Switch Employer Completed',
        AuthSignupCompleted: 'Auth Employee Signup Completed',
        AddShiftCalendar: 'Add Shift To Calendar Clicked',
        DismissShiftCalendar: 'Add Shift To Calendar Dismissed'
    },
    Payslip: {
        PageViewed: 'Payslip Page Viewed',
        Opened: 'Payslip Opened',
        DownloadInitiated: 'Payslip Download Initiated'
    },
    ActivationInitiated: 'Activation Initiated',
    PeoplePageViewed: 'People Page Viewed',
    Superannuation: {
        SuperStreamSubmissionStarted: 'SuperStream Submission Started',
        SuperStreamSubmissionSuccess: 'SuperStream Submission Success'
    },
    Notification: {
        Viewed: 'Notification Viewed',
        CreateRosterInitiated: 'Notification Create Roster Initiated',
        PublishRosterInitiated: 'Notification Publish Initiated'
    },
    AIRosterEarlyAccess: {
        SignupForEarlyAccessClicked: 'Sign-up for Early Access Clicked',
        SignupForEarlyAccessDelayed: 'Sign-up for Early Access Delayed',
        SignupForEarlyAccessCancelled: 'Sign-up for Early Access Cancelled',
        VIPDemoSessionDelayed: 'VIP Demo Session Delayed'
    },
    BookACallClicked: 'Book A Call Clicked',
    Navigation: {
        PayrollSTP: 'PayrollSTP Nav Clicked',
        People: 'People Nav Clicked',
        Reports: 'Reports Nav Clicked',
        Superannuation: 'Superannuation Nav Clicked',
        Refer: 'Refer a friend - Clicked Refer & Earn',
        AreYouAgent: 'Are You An Agent Link Clicked'
    },
    Download: {
        Ios: 'iOS App Download Link Clicked',
        Android: 'Android App Download Link Clicked'
    },
    Records: {
        PageViewed: 'Record Page Viewed'
    },
    AIRosterInitiated: 'AI Roster Initiated',
    NewTimeShiftDefaultAdded: 'New Time Shift Default Added',
    IndustrySelected: 'Industry Selected',
    IndustryAndRoleSubmitted: 'Industry and Role Submitted',
    NewRoleAdded: 'New Role Added',
    AIScheduleGenerationInitiated: 'AI Schedule Generation Initiated',
    AIScheduleGenerationCompleted: 'AI Schedule Generation Completed',
    RosterSaveTemplateInitiated: 'Roster Save Template Initiated',
    RosterSaveTemplateCompleted: 'Roster Save Template Completed',
    RosterInsertTemplateInitiated: 'Roster Insert Template Initiated',
    RosterInsertTemplateCompleted: 'Roster Insert Template Completed',
    RosterRoleCalendarViewed: 'Roster Role Calendar Viewed',
    RosterEmployeeCalendarViewed: 'Roster Employee Calendar Viewed',
    EmployeeAutoAssignInitiated: 'Employee Auto-Assign Initiated',
    EmployeeAutoAssignCompleted: 'Employee Auto-Assign Completed',
    SetBudgetInitiated: 'Set Budget Initiated',
    SetBudgetCompleted: 'Set Budget Completed',
    EmployeeProfileClicked: 'Employee Profile Clicked',
    AIRosterOptOutClicked: 'AI Roster Opt-out Clicked',
    UnavailabilityAdditionInitiated: 'Unavailability Addition Initiated',
    UnavailabilityAdditionCompleted: 'Unavailability Addition Completed',
    AllShiftDeletedClicked: 'All Shift Deleted Clicked',
    SampleEmployeesCleared: 'Sample Employees Cleared',
    AIRoster: {
        AIScheduleGenerationInitiated: 'AI Schedule Generation Initiated',
        AIScheduleGenerationCompleted: 'AI Schedule Generation Completed',
        AIScheduleRosterTemplateInitiated: 'AI Schedule Roster Template Initiated',
        AIScheduleRosterTemplateCompleted: 'AI Schedule Roster Template Completed',
        EmployeeAutoAssignInitiated: 'Employee Auto-Assign Initiated',
        EmployeeAutoAssignCompleted: 'Employee Auto-Assign Completed',
        OptInClicked: 'AI Roster Opt-in Clicked',
        EmployeeSelected: 'AI Roster Employee Selected'
    },
    EmployeePayrateAdditionInitiated: 'Employee Payrate Addition Initiated',
    ProactiveRoster: {
        SetupInitiated: 'Proactive Roster Setup Initiated',
        SetupCompleted: 'Proactive Roster Setup Completed'
    },
    OpenShift: {
        Viewed: 'Open Shift Viewed',
        Created: 'Open Shift Created',
        Approved: 'Open Shift Approved',
        Declined: 'Open Shift Declined',
        Taken: 'Open Shift Taken',
        Published: 'Open Shift Published'
    },
    SwapShift: {
        Initiated: 'Swap Shift Initiated'
    },
    RosterOpenShiftTabViewed: 'Roster Open Shift Tab Viewed',
    VariablePayRate: {
        Viewed: 'Variable Payrate Viewed',
        Added: 'Variable Payrate Added'
    },
    PublicHoliday: {
        View: 'Public Holiday View',
        Initiated: 'Public Holiday Initiated',
        Added: 'Public Holiday Added'
    }
};
exports.UserActivity = {
    Activation: 'activation',
    Engagement: 'engagement'
};
exports.UnavailabilityTriggeredFromLocations = {
    Calendar: 'calendar',
    EmployerLeavePage: 'employer leave page',
    EmployeeLeavePage: 'employee leave page'
};
exports.EmployeeRosterShiftViewedType = {
    OtherEmployeeShift: 'Other Employee Shift',
    MyShift: 'My Shift'
};
exports.VariablePayRateInitiatedAt = {
    Calendar: 'Calendar',
    PayRateSection: 'Pay rate section'
};
