import ngRedux from 'ng-redux'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { getRedirectFromPathname, isTsrOnly } from './controllers/consts'
import { getAllowedParams, getUrlParams } from './scheduling/utils/deeplink'
import { isDebug, setModalSubscription } from './scheduling/react-subscription/utils/share'
import { eventNames } from '@bookipi/utils'
import store from './store'
import '@payroller/pds/tailwind.css'
import '@bookipi/bds/tailwind.css'
import '../client/assets/css/tailwind.css'

var testMode = false
function loadScript(src) {
  return new Promise(function (resolve, reject) {
    var s
    s = document.createElement('script')
    s.src = src
    s.onload = resolve
    s.onerror = reject
    document.head.appendChild(s)
  })
}
// for internet explorer
if (!String.prototype.startsWith) {
  // eslint-disable-next-line
  String.prototype.startsWith = function (searchString, position) {
    position = position || 0
    return this.substr(position, searchString.length) === searchString
  }
}

const defaultRouteData = {
  title: 'Payroller Software Web Application - The Simplest Payroll Software',
  description: `The best payroll software system in ${new Date().getFullYear()}. Professional payslip in minutes. ATO Compliant.`,
  keyword: 'payroller, payroll, adp payroll, xero payroll, payroller on web'
}

export const angularApp = angular
  .module('starter', [
    ngRedux,
    'ngCookies',
    'ui.router',
    'ngSanitize',
    'starter.business.controllers',
    'ngAnimate',
    'smoothScroll',
    'starter.home.controllers',
    'starter.ai-roster.controllers',
    'starter.auth.controllers',
    'starter.agent.controllers',
    'starter.auth.factory',
    'starter.directives',
    'starter.services',
    'angular-loading-bar',
    'ngDialog',
    'ngDropover',
    'ngFileUpload',
    'ngSanitize',
    // "creditCardInput",
    'ui.bootstrap',
    'timer',
    'countrySelect',
    'ui.bootstrap',
    'slickCarousel', // https://github.com/devmark/angular-slick-carousel
    'autoCompleteModule',
    '720kb.datepicker',
    'daterangepicker',
    // "chart.js",
    'cp.ngConfirm',
    'oc.lazyLoad',
    'zxcvbn',
    'ui.bootstrap.datetimepicker'
  ])
  .config([
    '$ngReduxProvider',
    ($ngReduxProvider) => {
      $ngReduxProvider.provideStore(store)
    }
  ])
  .config(function (
    $stateProvider,
    $urlRouterProvider,
    $httpProvider,
    $compileProvider,
    $locationProvider,
    $stateRegistryProvider
  ) {
    $httpProvider.interceptors.push('TokenInterceptor')
    if (process.env.P_NODE_ENV !== 'production') {
      $stateProvider.state('create-test-account', {
        url: '/create-test-account',
        templateUrl: 'views/createTestAccount.html',
        controller: 'reactAppCtrl',
        access: {
          requiredLogin: false
        }
      })
    }

    $stateProvider
      .state('home', {
        template: '<div ui-view="homeContent" style="background:white"></div>',
        controller: 'HomeCtrl',
        access: {
          requiredLogin: false
        },
        resolve: {
          // Any property in resolve should return a promise and is executed before the view is loaded
          loadOcModal: [
            '$ocLazyLoad',
            '$injector',
            '$rootScope',
            function ($ocLazyLoad, $injector, $rootScope) {
              return $ocLazyLoad.load(['../assets-home/js/basic.js']).then(function () {})
            }
          ]
        }
      })
      .state('home.main', {
        url: '/',
        params: {
          demo: null
        },
        views: {
          homeContent: {
            templateUrl: 'views/home/blank.html'
          }
        },
        access: {
          requiredLogin: false
        },
        resolve: {
          loadOcModal: [
            '$ocLazyLoad',
            '$injector',
            '$rootScope',
            'AuthenticationFactory',
            function ($ocLazyLoad, $injector, $rootScope, AuthenticationFactory) {
              return $ocLazyLoad.load([]).then(function ($state) {
                if (AuthenticationFactory.isLogged && Capacitor.isNativePlatform()) {
                  window.location.href = '/business/schedule/timesheets'
                } else {
                  window.location.href = '/login'
                }
              })
            }
          ]
        }
      })

      .state('ai-roster', {
        url: '/ai-roster',
        templateUrl: 'views/ai-roster.html',
        controller: 'aiRosterCtrl',
        access: {
          requiredLogin: true
        }
      })

      .state('ai-roster.onboarding', {
        url: '/onboarding',
        views: {
          mainContent: {
            templateUrl: 'views/ai-roster-onboarding.html',
            controller: 'reactAppCtrl'
          }
        },
        access: {
          requiredLogin: true
        }
      })
      .state('login', {
        url: '/login?redirect&search&agentId&googleTokenId&activePayrun',
        templateUrl: 'views/login.html',
        params: {
          redirect: null,
          agentId: null,
          search: null,
          googleTokenId: null
        },
        controller: 'LogInCtrl',
        access: {
          requiredLogin: false
        },
        data: {
          title: 'Payroller Login',
          description: 'Login for STP | Payroller',
          keyword: 'payroller login'
        }
      })
      .state('unsubscribe', {
        url: '/unsubscribe/:code',
        templateUrl: 'views/unsubscribe.html'
      })
      .state('setupTfa', {
        url: '/setup-two-factor-authentication?redirect&search&agentId&activePayrun',
        params: {
          redirect: null,
          agentId: null,
          search: null
        },
        templateUrl: 'views/auth/setupTfa.html',
        controller: 'setupTfaCtrl',
        access: {
          requiredLogin: false
        },
        resolve: {
          setupTfaCtrl: [
            '$q',
            '$ocLazyLoad',
            function ($q, $ocLazyLoad) {
              let deferred = $q.defer()
              require.ensure([], function () {
                let module = require('./controllers/auth/setupTfaCtrl.js')
                $ocLazyLoad.load({
                  name: 'starter.auth.controllers.setupTfaCtrl'
                })
                deferred.resolve(module)
              })
              return deferred.promise
            }
          ]
        }
      })
      .state('verifyTfa', {
        url: '/two-factor-authentication?redirect&search&agentId&activePayrun',
        templateUrl: 'views/auth/verifyTfa.html',
        params: {
          redirect: null,
          search: null,
          agentId: null
        },
        controller: 'verifyTfaCtrl',
        access: {
          requiredLogin: false
        },
        resolve: {
          verifyTfaCtrl: [
            '$q',
            '$ocLazyLoad',
            function ($q, $ocLazyLoad) {
              let deferred = $q.defer()
              require.ensure([], function () {
                let module = require('./controllers/auth/verifyTfaCtrl.js')
                $ocLazyLoad.load({
                  name: 'starter.auth.controllers.verifyTfaCtrl'
                })
                deferred.resolve(module)
              })
              return deferred.promise
            }
          ]
        }
      })
      .state('resetTfaViaEmail', {
        url: '/reset-two-factor-authentication-via-email',
        templateUrl: 'views/auth/resetTfaViaEmail.html',
        params: {
          redirect: null,
          search: null,
          agentId: null
        },
        controller: 'resetTfaViaEmailCtrl',
        access: {
          requiredLogin: false
        },
        resolve: {
          resetTfaViaEmailCtrl: [
            '$q',
            '$ocLazyLoad',
            function ($q, $ocLazyLoad) {
              let deferred = $q.defer()
              require.ensure([], function () {
                let module = require('./controllers/auth/resetTfaViaEmailCtrl.js')
                $ocLazyLoad.load({
                  name: 'starter.auth.controllers.resetTfaViaEmailCtrl'
                })
                deferred.resolve(module)
              })
              return deferred.promise
            }
          ]
        }
      })
      .state('resetTfa', {
        url: '/reset-two-factor-authentication/:userId/:randomCode',
        templateUrl: 'views/auth/resetTfa.html',
        controller: 'resetTfaCtrl',
        access: {
          requiredLogin: false
        },
        resolve: {
          resetTfaCtrl: [
            '$q',
            '$ocLazyLoad',
            function ($q, $ocLazyLoad) {
              let deferred = $q.defer()
              require.ensure([], function () {
                let module = require('./controllers/auth/resetTfaCtrl.js')
                $ocLazyLoad.load({
                  name: 'starter.auth.controllers.resetTfaCtrl'
                })
                deferred.resolve(module)
              })
              return deferred.promise
            }
          ]
        }
      })
      .state('verifyViaEmail', {
        url: '/verify-via-email?redirect&search&agentId&activePayrun',
        templateUrl: 'views/auth/verifyViaEmail.html',
        params: {
          redirect: null,
          search: null,
          agentId: null
        },
        controller: 'verifyViaEmailCtrl',
        access: {
          requiredLogin: false
        },
        resolve: {
          verifyViaEmailCtrl: [
            '$q',
            '$ocLazyLoad',
            function ($q, $ocLazyLoad) {
              let deferred = $q.defer()
              require.ensure([], function () {
                let module = require('./controllers/auth/verifyViaEmailCtrl.js')
                $ocLazyLoad.load({
                  name: 'starter.auth.controllers.verifyViaEmailCtrl'
                })
                deferred.resolve(module)
              })
              return deferred.promise
            }
          ]
        }
      })
      .state('signup', {
        url: '/signup?agentId&cte&ctf&ctl&ctc&mid&contactId&redirect&search&googleTokenId&email&referredBy&promo&form&referredByFriend&q1&variant',
        params: {
          agentId: null,
          cte: null,
          ctf: null,
          ctl: null,
          ctc: null,
          mid: null,
          contactId: null,
          redirect: null,
          search: null,
          googleTokenId: null,
          email: null,
          referredBy: null,
          promo: null,
          form: null,
          referredByFriend: null,
          q1: null,
          variant: null
        },
        templateUrl: 'views/auth/onboarding.html',
        controller: 'OnboardingCtrl',
        access: {
          requiredLogin: false
        },
        resolve: {
          OnboardingCtrl: [
            '$q',
            '$ocLazyLoad',
            function ($q, $ocLazyLoad) {
              let deferred = $q.defer()
              require.ensure([], function () {
                let module = require('./controllers/auth/onboardingCtrl.js')
                $ocLazyLoad.load({
                  name: 'starter.auth.controllers.onboardingCtrl'
                })
                deferred.resolve(module)
              })
              return deferred.promise
            }
          ]
        },
        data: {
          title: 'Payroller Web Signup ',
          description: 'Signup for STP on web | Payroller',
          keyword: 'payroller signup web'
        }
      })
      .state('initialSetup', {
        url: '/initial-setup',
        templateUrl: 'views/auth/initialSetup.html',
        controller: 'InitialSetupCtrl',
        access: {
          requiredLogin: true
        },
        resolve: {
          InitialSetupCtrl: [
            '$q',
            '$ocLazyLoad',
            function ($q, $ocLazyLoad) {
              let deferred = $q.defer()
              require.ensure([], function () {
                let module = require('./controllers/auth/initialSetupCtrl.js')
                $ocLazyLoad.load({
                  name: 'starter.auth.controllers.initialSetupCtrl'
                })
                deferred.resolve(module)
              })
              return deferred.promise
            }
          ]
        }
      })
      .state('tryDemo', {
        url: '/try-demo?agentId&?cte&?ctf&?ctl&?ctc&?mid&?contactId&?redirect&?search',
        params: {
          agentId: null,
          cte: null,
          ctf: null,
          ctl: null,
          ctc: null,
          mid: null,
          contactId: null,
          redirect: null,
          search: null
        },
        templateUrl: 'views/auth/simpleSignup.html',
        controller: 'SimpleSignUpCtrl',
        access: {
          requiredLogin: false
        },
        resolve: {
          SimpleSignUpCtrl: [
            '$q',
            '$ocLazyLoad',
            function ($q, $ocLazyLoad) {
              let deferred = $q.defer()
              require.ensure([], function () {
                let module = require('./controllers/auth/simpleSignUpCtrl.js')
                $ocLazyLoad.load({
                  name: 'starter.auth.controllers.simpleSignUpCtrl'
                })
                deferred.resolve(module)
              })
              return deferred.promise
            }
          ]
        },
        data: {
          title: 'Try Payroller | Payroller Demo - The Simplest Payroll Software',
          description:
            'Try Payroller for free. 14-day free trial. The best and easiest payroll software. Download the iOS or Android app'
        }
      })
      .state('verifyEmail', {
        url: '/verify/:email/:validCode',
        templateUrl: 'views/auth/verifyEmail.html',
        controller: 'VerifyEmailCtrl',
        access: {
          requiredLogin: false
        },
        resolve: {
          VerifyEmailCtrl: [
            '$q',
            '$ocLazyLoad',
            function ($q, $ocLazyLoad) {
              let deferred = $q.defer()
              require.ensure([], function () {
                let module = require('./controllers/auth/verifyEmailCtrl.js')
                $ocLazyLoad.load({
                  name: 'starter.auth.controllers.verifyEmailCtrl'
                })
                deferred.resolve(module)
              })
              return deferred.promise
            }
          ]
        }
      })
      .state('resetPassword-old', {
        url: '/reset-password/:email/:validCode',
        templateUrl: 'views/auth/resetPassword.html',
        controller: 'ResetPasswordCtrl',
        access: {
          requiredLogin: false
        },
        resolve: {
          ResetPasswordCtrl: [
            '$q',
            '$ocLazyLoad',
            function ($q, $ocLazyLoad) {
              let deferred = $q.defer()
              require.ensure([], function () {
                let module = require('./controllers/auth/resetPasswordCtrl.js')
                $ocLazyLoad.load({
                  name: 'starter.auth.controllers.resetPasswordCtrl'
                })
                deferred.resolve(module)
              })
              return deferred.promise
            }
          ]
        }
      })
      .state('resetPassword', {
        url: '/reset-password/:email/:validCode?app',
        templateUrl: 'views/auth/resetPassword.html',
        controller: 'ResetPasswordCtrl',
        access: {
          requiredLogin: false
        },
        resolve: {
          ResetPasswordCtrl: [
            '$q',
            '$ocLazyLoad',
            function ($q, $ocLazyLoad) {
              let deferred = $q.defer()
              require.ensure([], function () {
                let module = require('./controllers/auth/resetPasswordCtrl.js')
                $ocLazyLoad.load({
                  name: 'starter.auth.controllers.resetPasswordCtrl'
                })
                deferred.resolve(module)
              })
              return deferred.promise
            }
          ]
        }
      })
      .state('setPassword', {
        url: '/set-password/:email/:validCode',
        templateUrl: 'views/auth/setPassword.html',
        controller: 'ResetPasswordCtrl',
        access: {
          requiredLogin: false
        },
        resolve: {
          ResetPasswordCtrl: [
            '$q',
            '$ocLazyLoad',
            function ($q, $ocLazyLoad) {
              let deferred = $q.defer()
              require.ensure([], function () {
                let module = require('./controllers/auth/resetPasswordCtrl.js')
                $ocLazyLoad.load({
                  name: 'starter.auth.controllers.resetPasswordCtrl'
                })
                deferred.resolve(module)
              })
              return deferred.promise
            }
          ]
        }
      })
      .state('forgotPassword', {
        url: '/forgot-password',
        // abstract: true,
        params: {
          hasCoupon: null,
          couponCode: null
        },
        templateUrl: 'views/forgot-password.html',
        controller: 'ForgotPasswordCtrl',
        access: {
          requiredLogin: false
        },
        resolve: {
          ForgotPasswordCtrl: [
            '$q',
            '$ocLazyLoad',
            function ($q, $ocLazyLoad) {
              let deferred = $q.defer()
              require.ensure([], function () {
                let module = require('./controllers/auth/authCtrl/forgotPasswordCtrl.js')
                $ocLazyLoad.load({
                  name: 'starter.auth.controllers.forgotpw'
                })
                deferred.resolve(module)
              })
              return deferred.promise
            }
          ]
        }
      })
      .state('payrollerEmployee', {
        url: '/employee-account-setup/:_id?app',
        params: {
          app: null
        },
        templateUrl: 'views/payrollerEmployee.html',
        controller: 'payrollerEmployeeCtrl',
        access: {
          requiredLogin: false
        },
        resolve: {
          setupEmployeeCtrl: [
            '$q',
            '$ocLazyLoad',
            function ($q, $ocLazyLoad) {
              let deferred = $q.defer()
              require.ensure([], function () {
                let module = require('./controllers/payrollerEmployeeCtrl.js')
                $ocLazyLoad.load({
                  name: 'starter.auth.controllers.payrollerEmployeeCtrl'
                })
                deferred.resolve(module)
              })
              return deferred.promise
            }
          ]
        }
      })
      .state('timesheetAppDownload', {
        url: '/timesheet-app-download?action&t&u&s&e&newUser',
        params: {
          action: null
        },
        templateUrl: 'views/payrollerTSR.html',
        controller: 'payrollerTsrCtrl',
        access: {
          requiredLogin: false
        }
      })
      .state('err404', {
        url: '/404-not-found',
        templateUrl: 'views/404.html',
        access: {
          requiredLogin: false
        }
      })
      .state('business', {
        url: '/business?t&u&s&e&newUser&isRedirectedFromV2&noRedirection&isTsrOnly',
        reloadOnSearch: false,
        abstract: true,
        templateUrl: 'views/business/business.html',
        controller: 'businessCtrl',
        access: {
          requiredLogin: true
        },
        params: {
          t: null,
          u: null,
          s: null,
          e: null,
          newUser: null
        },
        resolve: {
          // Any property in resolve should return a promise and is executed before the view is loaded
          loadOcModal: [
            '$ocLazyLoad',
            '$injector',
            '$rootScope',
            function ($ocLazyLoad, $injector, $rootScope) {
              return $ocLazyLoad
                .load([
                  './assets-home/css/theme.css',
                  'https://cdnjs.cloudflare.com/ajax/libs/across-tabs/1.0.0/across-tabs.min.js'
                ])
                .then(function () {})
            }
          ]
        }
      })
      .state('business.settings', {
        url: '/settings?open&t&e&u',
        views: {
          mainContent: {
            templateUrl: 'views/business/settings.html',
            controller: 'settingCtrl'
          }
        },
        access: {
          requiredLogin: true
        },
        resolve: {
          settingCtrl: [
            '$q',
            '$ocLazyLoad',
            function ($q, $ocLazyLoad) {
              let deferred = $q.defer()
              require.ensure([], function () {
                let module = require('./controllers/business/settingCtrl.js')
                $ocLazyLoad.load({
                  name: 'starter.business.controllers'
                })
                deferred.resolve(module)
              })
              return deferred.promise
            }
          ]
        }
      })
      .state('business.leaves', {
        url: '/leaves',
        views: {
          mainContent: {
            templateUrl: 'views/business/leaves.html',
            controller: 'reactAppCtrl'
          }
        },
        access: {
          requiredLogin: true
        }
      })
      .state('business.leave', {
        url: '/leave',
        views: {
          mainContent: {
            templateUrl: 'views/business/leave.html'
            // controller: 'profileCtrl'
          }
        },
        access: {
          requiredLogin: true
        }
      })
      .state('business.leave.request', {
        url: '/request',
        views: {
          leaveContent: {
            templateUrl: 'views/business/leaveRequest.html',
            controller: 'leaveRequestCtrl'
          }
        },
        access: {
          requiredLogin: true
        }
      })
      .state('business.leave.schedule', {
        url: '/schedule',
        views: {
          leaveContent: {
            templateUrl: 'views/business/leaveSchedule.html',
            controller: 'leaveScheduleCtrl'
          }
        },
        access: {
          requiredLogin: true
        }
      })

    // TSR SPECIFIC PAGES
    if (process.env.APP === 'people') {
      $stateProvider
        .state('business.employeetimesheets', {
          url: '/employeetimesheets',
          views: {
            mainContent: {
              templateUrl: 'views/business/employee/employeetimesheets.html',
              controller: 'reactAppCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.employee-roster', {
          url: '/employee-roster',
          views: {
            mainContent: {
              templateUrl: 'views/business/employee/roster.html',
              controller: 'reactAppCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.employee-leave', {
          url: '/employee-leave',
          views: {
            mainContent: {
              templateUrl: 'views/business/employee/leave.html',
              controller: 'reactAppCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.notification', {
          url: '/notification',
          views: {
            mainContent: {
              templateUrl: 'views/business/employee/notification.html',
              controller: 'reactAppCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
    }

    if (!Capacitor.isNativePlatform()) {
      $stateProvider
        .state('invitation-payment-page', {
          url: '/invitation-payment-page',
          templateUrl: 'views/invitation-payment-page.html',
          controller: 'reactAppCtrl',
          access: {
            requiredLogin: false
          }
        })
        .state('business.getStarted', {
          url: '/get-started',
          views: {
            mainContent: {
              templateUrl: 'views/business/getStarted.html',
              controller: 'getStartedCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.import', {
          url: '/import',
          views: {
            mainContent: {
              templateUrl: 'views/business/import.html'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.import.employees', {
          url: '/employees',
          views: {
            importContent: {
              templateUrl: 'views/business/importPeople.html',
              controller: 'importPeopleCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.run', {
          url: '/run',
          views: {
            mainContent: {
              templateUrl: 'views/business/run.html',
              controller: 'runCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.run.overview', {
          url: '/overview?agentId?activePayrun',
          views: {
            runContent: {
              templateUrl: 'views/business/run/runOverview.html',
              controller: 'runOverviewCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.run.payroll', {
          // url: "/payroll/",
          url: '/payroll/:payRunId/:startDate/:endDate/:paymentDate/:payPeriod/:timesheetImport',
          views: {
            runContent: {
              templateUrl: 'views/business/run/runPayroll.html',
              controller: 'runPayrollCtrl'
            }
          },
          access: {
            requiredLogin: true
          },
          resolve: {
            runPayrollCtrl: [
              '$q',
              '$ocLazyLoad',
              function ($q, $ocLazyLoad) {
                let deferred = $q.defer()
                require.ensure([], function () {
                  let module = require('./controllers/business/runPayrollCtrl.js')
                  $ocLazyLoad.load({
                    name: 'starter.business.controllers'
                  })
                  deferred.resolve(module)
                })
                return deferred.promise
              }
            ]
          }
        })
        .state('business.people', {
          url: '/people',
          views: {
            mainContent: {
              templateUrl: 'views/business/people.html',
              controller: 'peopleCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.people-v2', {
          url: '/people-v2',
          views: {
            mainContent: {
              templateUrl: 'views/business/people-v2.html',
              controller: 'peopleCtrlV2'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.documents', {
          url: '/documents',
          views: {
            mainContent: {
              templateUrl: 'views/business/documents.html',
              controller: 'documentsCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.superStream-v2', {
          url: '/superstream-v2',
          views: {
            mainContent: {
              templateUrl: 'views/business/superStream-v2.html',
              controller: 'superCtrlV2'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.settings-v2', {
          url: '/settings-v2?from&tab&connectXero',
          views: {
            mainContent: {
              templateUrl: 'views/business/settings-v2.html',
              controller: 'settingsCtrlV2'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.reports-v2', {
          url: '/reports-v2',
          views: {
            mainContent: {
              templateUrl: 'views/business/reports-v2.html',
              controller: 'reportsCtrlV2'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.timesheets', {
          url: '/schedule/timesheets',
          views: {
            mainContent: {
              templateUrl: 'views/business/schedule/timesheets.html',
              controller: 'reactAppCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.rosters', {
          url: '/schedule/rosters',
          views: {
            mainContent: {
              templateUrl: 'views/business/schedule/rosters.html',
              controller: 'reactAppCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.rosters-v2', {
          url: '/schedule/rosters-v2',
          views: {
            mainContent: {
              templateUrl: 'views/business/schedule/roster-v2.html',
              controller: 'rostersCtrlV2'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.finaliseSTP', {
          url: '/finalise-stp',
          views: {
            mainContent: {
              templateUrl: 'views/business/finalise-stp.html',
              controller: 'finaliseStpCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.jobmaker', {
          url: '/jobmaker',
          views: {
            mainContent: {
              templateUrl: 'views/business/jobmaker.html',
              controller: 'jobmakerCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.jobmaker.employees', {
          url: '/employees',
          views: {
            jobmakerContent: {
              templateUrl: 'views/business/jobmakerEmployees.html',
              controller: 'leaveRequestCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.jobmaker.reports', {
          url: '/reports',
          views: {
            jobmakerContent: {
              templateUrl: 'views/business/jobmakerReports.html',
              controller: 'leaveScheduleCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.reports', {
          url: '/reports',
          views: {
            mainContent: {
              templateUrl: 'views/business/reports.html',
              controller: 'reportCtrl'
            }
          },
          access: {
            requiredLogin: true
          },
          resolve: {
            // Any property in resolve should return a promise and is executed before the view is loaded
            reportCtrl: [
              '$q',
              '$ocLazyLoad',
              function ($q, $ocLazyLoad) {
                let deferred = $q.defer()
                require.ensure([], function () {
                  let module = require('./controllers/business/reportCtrl.js')
                  $ocLazyLoad.load({
                    name: 'starter.business.controllers'
                  })
                  deferred.resolve(module)
                })
                return deferred.promise
              }
            ],
            loadOcModal: [
              '$ocLazyLoad',
              '$injector',
              '$rootScope',
              function ($ocLazyLoad, $injector, $rootScope) {
                return $ocLazyLoad.load({
                  serie: true,
                  name: 'chart.js',
                  files: [
                    'https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.7.3/Chart.min.js',
                    'https://cdnjs.cloudflare.com/ajax/libs/angular-chart.js/1.1.1/angular-chart.min.js'
                  ]
                })
              }
            ]
          }
        })
        .state('business.superStream', {
          url: '/superstream',
          views: {
            mainContent: {
              templateUrl: 'views/business/superStream.html',
              controller: 'superCtrl'
            }
          },
          access: {
            requiredLogin: true
          },
          resolve: {
            superCtrl: [
              '$q',
              '$ocLazyLoad',
              function ($q, $ocLazyLoad) {
                let deferred = $q.defer()
                require.ensure([], function () {
                  let module = require('./controllers/business/superCtrl.js')
                  $ocLazyLoad.load({
                    name: 'starter.business.controllers'
                  })
                  deferred.resolve(module)
                })
                return deferred.promise
              }
            ]
          }
        })
        .state('business.addons', {
          url: '/addons',
          views: {
            mainContent: {
              templateUrl: 'views/business/addons.html',
              controller: 'addonCtrl'
            }
          },
          access: {
            requiredLogin: true
          },
          resolve: {}
        })
        .state('business.superStream.readyToSubmit', {
          url: '/ready-to-submit',
          views: {
            superContent: {
              templateUrl: 'views/business/superStream/superReadyToSubmit.html'
              // controller: "leaveRequestCtrl"
            }
          },
          access: {
            requiredLogin: true,
            isRegistered: true
          }
        })
        .state('business.superStream.submitted', {
          url: '/submitted',
          views: {
            superContent: {
              templateUrl: 'views/business/superStream/superSubmitted.html'
              // controller: "leaveRequestCtrl"
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.superStream.ignored', {
          url: '/ignored',
          views: {
            superContent: {
              templateUrl: 'views/business/superStream/superIgnored.html'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('setupEmployee', {
          url: '/employee-setup/:employeeId/:code',
          templateUrl: 'views/setup-employee.html',
          controller: 'setupEmployeeCtrl',
          access: {
            requiredLogin: false
          },
          resolve: {
            setupEmployeeCtrl: [
              '$q',
              '$ocLazyLoad',
              function ($q, $ocLazyLoad) {
                let deferred = $q.defer()
                require.ensure([], function () {
                  let module = require('./controllers/setupEmployeeCtrl.js')
                  $ocLazyLoad.load({
                    name: 'starter.setupEmployee'
                  })
                  deferred.resolve(module)
                })
                return deferred.promise
              }
            ]
          }
        })
        .state('joinSpaceship', {
          url: '/join-spaceship/:employeeId/:code?touchpoint',
          templateUrl: 'views/setup-employee.html',
          controller: 'setupEmployeeCtrl',
          params: {
            joinSpaceship: true
          },
          access: {
            requiredLogin: false
          },
          resolve: {
            setupEmployeeCtrl: [
              '$q',
              '$ocLazyLoad',
              function ($q, $ocLazyLoad) {
                let deferred = $q.defer()
                require.ensure([], function () {
                  let module = require('./controllers/setupEmployeeCtrl.js')
                  $ocLazyLoad.load({
                    name: 'starter.setupEmployee'
                  })
                  deferred.resolve(module)
                })
                return deferred.promise
              }
            ]
          }
        })
        .state('verifyReseller', {
          url: '/verify-reseller/:resellerId/:code',
          templateUrl: 'views/agent/verify-reseller.html',
          controller: 'verifyResellerCtrl',
          access: {
            requiredLogin: false
          }
        })
        .state('agent', {
          url: '/agent?t&u&s&e&newUser&clientId&isRedirectedFromV2',
          abstract: true,
          templateUrl: 'views/agent/agent.html',
          controller: 'agentCtrl',
          reloadOnSearch: false,
          params: {
            t: null,
            u: null,
            s: null,
            e: null,
            newUser: null
          },
          access: {
            // requiredLogin_reseller: true
          },
          resolve: {
            // Any property in resolve should return a promise and is executed before the view is loaded
            loadOcModal: [
              '$ocLazyLoad',
              '$injector',
              '$rootScope',
              function ($ocLazyLoad, $injector, $rootScope) {
                return $ocLazyLoad
                  .load([
                    // "./assets-home/css/theme.css",
                  ])
                  .then(function () {})
              }
            ]
          }
        })
        .state('agent.overview', {
          url: '/overview',
          views: {
            mainContent: {
              templateUrl: 'views/agent/agent.overview.html',
              controller: 'resellerOverviewCtrl'
            }
          },
          access: {
            // requiredLogin_reseller: true
          }
        })
        .state('agent.bulkRun', {
          url: '/bulk-run',
          views: {
            mainContent: {
              templateUrl: 'views/agent/agent.bulkRun.html',
              controller: 'agentBulkRunCtrl'
            }
          },
          access: {
            // requiredLogin_reseller: true
          }
        })
        .state('agent.bulkRun.selectPayroll', {
          url: '/payroll',
          views: {
            runContent: {
              templateUrl: 'views/agent/agent.bulkRun.selectPayroll.html',
              controller: 'agentBulkRunSelectPayrollCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('agent.bulkRun.selectStp', {
          url: '/stp-report',
          views: {
            runContent: {
              templateUrl: 'views/agent/agent.bulkRun.selectStp.html',
              controller: 'agentBulkRunSelectStpCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('agent.bulkRun.payroll', {
          url: '/payroll/:payRunId/:startDate/:endDate/:paymentDate/:payPeriod?isRedirectedFromV2',
          views: {
            runContent: {
              templateUrl: 'views/business/run/runPayroll.html',
              controller: 'runPayrollCtrl'
            }
          },
          access: {
            requiredLogin: true
          },
          resolve: {
            runPayrollCtrl: [
              '$q',
              '$ocLazyLoad',
              function ($q, $ocLazyLoad) {
                let deferred = $q.defer()
                require.ensure([], function () {
                  let module = require('./controllers/business/runPayrollCtrl.js')
                  $ocLazyLoad.load({
                    name: 'starter.business.controllers'
                  })
                  deferred.resolve(module)
                })
                return deferred.promise
              }
            ]
          }
        })
        .state('agent.bulkRun.loading', {
          url: '/loading',
          views: {
            runContent: {
              templateUrl: 'views/agent/agent.bulkRun.loading.html'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('agent.profit', {
          url: '/profit',
          views: {
            mainContent: {
              templateUrl: 'views/agent/agent.profit.html'
              // controller: "overviewCtrl"
            }
          },
          access: {
            // requiredLogin_reseller: true
          }
        })
        .state('agent.clients', {
          url: '/clients?t&u&s&e&redirect',
          views: {
            mainContent: {
              templateUrl: 'views/agent/agent.clients.html',
              controller: 'agentClientsCtrl'
            }
          },
          access: {
            // requiredLogin_reseller: true
          }
        })
        .state('agent.referral-program', {
          url: '/referral-program',
          views: {
            mainContent: {
              templateUrl: 'views/agent/agent.referral.program.html',
              controller: 'agentProgramCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('agent.import', {
          url: '/import',
          views: {
            mainContent: {
              templateUrl: 'views/agent/agent.import.html'
              // controller: "agentImportClientCtrl"
            }
          },
          access: {
            // requiredLogin_reseller: true
          }
        })
        .state('agent.import.business', {
          url: '/business',
          views: {
            importContent: {
              templateUrl: 'views/agent/agent.import.business.html',
              controller: 'agentImportBusinessCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('agent.import.employee', {
          url: '/employee',
          views: {
            importContent: {
              templateUrl: 'views/agent/agent.import.employee.html',
              controller: 'agentImportEmployeeCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('agent.importClients', {
          url: '/import-clients',
          views: {
            mainContent: {
              templateUrl: 'views/agent/agent.importClients.html',
              controller: 'agentImportClientCtrl'
            }
          },
          access: {
            // requiredLogin_reseller: true
          }
        })
        .state('agent.share', {
          url: '/share',
          views: {
            mainContent: {
              templateUrl: 'views/agent/agent.share.html'
              // controller: "overviewCtrl"
            }
          },
          access: {
            // requiredLogin_reseller: true
          }
        })
        .state('agent.settings', {
          url: '/settings?step',
          views: {
            mainContent: {
              templateUrl: 'views/agent/agent.settings.html',
              controller: 'agentSettingsCtrl'
            }
          },
          access: {
            // requiredLogin_reseller: true
          }
        })
        .state('agent.connectClient', {
          url: '/connect-client/:verificationCode',
          views: {
            mainContent: {
              templateUrl: 'views/agent/agent.connect.html',
              controller: 'agentConnectClientCtrl'
            }
          },
          access: {
            // requiredLogin_reseller: true
          }
        })
    } else {
      $stateProvider
        .state('business.people', {
          url: '/people',
          views: {
            mainContent: {
              templateUrl: 'views/business/people.html',
              controller: 'peopleCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.timesheets', {
          url: '/schedule/timesheets',
          views: {
            mainContent: {
              templateUrl: 'views/business/schedule/timesheets.html',
              controller: 'reactAppCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
        .state('business.rosters', {
          url: '/schedule/rosters',
          views: {
            mainContent: {
              templateUrl: 'views/business/schedule/rosters.html',
              controller: 'reactAppCtrl'
            }
          },
          access: {
            requiredLogin: true
          }
        })
    }

    $urlRouterProvider.otherwise(function ($injector) {
      const pathname = window.location.pathname
      const redirect = getRedirectFromPathname(pathname)
      const $state = $injector.get('$state')
      const defaultRedirect = Capacitor.isNativePlatform() ? 'login' : 'err404'
      $state.go(redirect || defaultRedirect)
    })

    $locationProvider.html5Mode(true)
  })
  .run(function (
    $rootScope,
    AuthenticationFactory,
    $window,
    $state,
    $timeout,
    $location,
    $transitions,
    Analytics,
    Http,
    Service,
    STPService,
    $cookies,
    Logout,
    $ngRedux,
    GLOBALS
  ) {
    delete $window.localStorage.demo

    $rootScope.demo = false
    $rootScope.GLOBALS = GLOBALS

    Http(
      {
        method: 'get',
        api: '/hand-shake/',
        disableErrorMsg: true,
        noRefresh: process.env.IS_SANDBOX === 'true'
      },
      (res) => {
        $cookies.put('_surf', res.csrfToken)
      },
      (err) => {
        console.log('hand shake failed')
        // $state.go("err404");
        // redirect 404 page
      }
    )

    var resellerData = {
      u: 'payroller',
      p1: 10.0
    }
    $rootScope.resellerData = resellerData

    var hostArray = window.location.hostname.split('.')
    if (hostArray[hostArray.length - 2] === 'payrolls') {
      $rootScope.whiteLabeled = true
      var resellerUrl = hostArray[hostArray.length - 3]

      Http(
        {
          method: 'get',
          api: '/white-label/' + resellerUrl,
          disableErrorMsg: true
        },
        (res) => {
          $rootScope.whiteLabelServiceName = res.serviceName
          $window.localStorage.domain_agent_id = res.agentId
        },
        (err) => {
          $state.go('err404')
          // redirect 404 page
        }
      )
    }

    // check IE
    function detectIE() {
      var ua = window.navigator.userAgent

      var msie = ua.indexOf('MSIE ')
      if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
      }

      var trident = ua.indexOf('Trident/')
      if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:')
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
      }

      var edge = ua.indexOf('Edge/')
      if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
      }

      // other browser
      return false
    }
    $rootScope.isIE = detectIE()

    AuthenticationFactory.check()
    // resellerData 가 비어 있을 경우 데이터 없음 페이지로 이동
    $transitions.onSuccess({ to: true }, ($transition) => {
      document.body.scrollTop = document.documentElement.scrollTop = 0
      var toState = $transition.to()

      // home 으로 접근 access 권한 필요없음
      // admin으로 접근 access 권한 필요
      // reseller-admin 으로 접근, access 권한 필요

      if (
        toState.access &&
        toState.access.requiredLogin &&
        !AuthenticationFactory.isLogged &&
        $window.localStorage.demo !== 'true'
      ) {
        $timeout(function () {
          $state.go('login', {
            // redirect: window.location.pathname
          })
        })
      }
      // If you have not registered and require registration, the registration modal will be displayed
      if (toState.access.isRegistered && !AuthenticationFactory.isRegistered) {
        $timeout(function () {
          $ngRedux.dispatch(
            setModalSubscription({
              source: 'superStreamReadyToSubmit'
            })
          )
        })
      }

      // set or replace canonical tag
      var toStateUrl = toState.url
      toStateUrl = toStateUrl.substring(0, toStateUrl.indexOf('?'))

      // eslint-disable-next-line
      var link = !!document.querySelector("link[rel='canonical']")
        ? document.querySelector("link[rel='canonical']")
        : document.createElement('link')
      link.setAttribute('rel', 'canonical')
      link.setAttribute('href', location.protocol + '//' + location.host + toStateUrl)
      document.head.appendChild(link)

      // set title & description by url
      const siteMeta = toState.data || defaultRouteData
      document.title = (isDebug ? `[${process.env.P_NODE_ENV}] ` : '') + siteMeta.title
      document.querySelector('meta[name="description"]').setAttribute('content', siteMeta.description)
      document
        .querySelector('meta[name="keyword"]')
        .setAttribute('content', siteMeta.keyword || defaultRouteData.keyword)
    })

    // global module
    $rootScope.getShortName = (firstName, lastName) => {
      return `${firstName} ${lastName ? lastName.charAt(0).toUpperCase() + '.' : ''}`
    }

    if (process.env.APP === 'people') {
      $window.sessionStorage.isTsrOnly = true

      /**
       * This handles redirection when user is logged in
       */
      if (AuthenticationFactory?.isLogged && Capacitor.isNativePlatform()) {
        setTimeout(() => {
          if (AuthenticationFactory?.isEmployer) {
            $state.go('business.rosters')
          } else if (AuthenticationFactory?.isEmployee) {
            $state.go('business.clock')
          }
        }, 50)
      }
    }

    /**
     * TSR Deeplink implementation
     */
    if (isTsrOnly) {
      App.addListener('appUrlOpen', (event) => {
        if (event?.url) {
          const { pathName, queryParams } = getUrlParams(event?.url)
          switch (pathName) {
            // TSR signup redirection
            case 'tsr-signup':
              const queryObj = getAllowedParams(queryParams, ['t', 'e', 'u', 's', 'newUser'])
              $state.go('business.rosters', queryObj)
              break
          }
        }
      })
    }

    const initAppEvent = () => {
      const appOpen = sessionStorage.getItem('appOpened')
      if (appOpen) return

      Analytics.logEvent(eventNames.AppOpen)
      sessionStorage.setItem('appOpened', 'true')
    }
    initAppEvent()
  })
  .directive('head', [
    '$rootScope',
    '$compile',
    function ($rootScope, $compile) {
      return {
        restrict: 'E',
        link: function (scope, elem) {
          var html = '<link rel="stylesheet" ng-repeat="(routeCtrl, cssUrl) in routeStyles" ng-href="{{cssUrl}}" />'
          elem.append($compile(html)(scope))
          scope.routeStyles = {}
          $rootScope.$on('$routeChangeStart', function (e, next, current) {
            if (current && current.$$route && current.$$route.css) {
              if (!angular.isArray(current.$$route.css)) {
                current.$$route.css = [current.$$route.css]
              }
              angular.forEach(current.$$route.css, function (sheet) {
                delete scope.routeStyles[sheet]
              })
            }
            if (next && next.$$route && next.$$route.css) {
              if (!angular.isArray(next.$$route.css)) {
                next.$$route.css = [next.$$route.css]
              }
              angular.forEach(next.$$route.css, function (sheet) {
                scope.routeStyles[sheet] = sheet
              })
            }
          })
        }
      }
    }
  ])
  .directive('helper', [
    'Service',
    function (Service) {
      return {
        restrict: 'CA',
        replace: false,
        transclude: false,
        template:
          '<i class="icon-feather-help-circle text-primary fs-14 pointer" style="vertical-align: middle" ng-click="getInfo_defaultPayrollLiabilityAccount()"></i>',
        link: function (scope, elem, attrs) {
          elem.bind('click', function () {
            Service.infoPopup({
              title: attrs.title,
              contents: attrs.contents
            })
          })
        }
      }
    }
  ])
  // to prevent “Possibly unhandled rejection” error
  .config([
    '$qProvider',
    function ($qProvider) {
      $qProvider.errorOnUnhandledRejections(false)
    }
  ])
