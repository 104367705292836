import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePaywall } from '../usePaywall'
import { useMutation } from '../../../../../../api/paywall'
import { PROGRESS } from '../../../../../constant'
import { useAlerts, useModal } from '../../../../../hooks'
import { getGcid, getGcSessionId } from '../../../../../react-subscription/utils/share'
import { setSubscribed, setPaymentMethod } from '../../../../../../reducers/subscriptionSlice'
import { isValidMobileSubscription } from '../../../../../../utils/subscription'

export function useProgress() {
  const [progress, setProgress] = useState()
  const dispatch = useDispatch()
  const [subscribe, { data, isError, isLoading }] = useMutation()
  const { subscriptionStatus } = useSelector((state) => ({
    subscriptionStatus: state.app.subscriptionStatus
  }))
  const { period, seats, paymentMethod, tokenReference, tokenizeStatus, removeStoredPaymentmethod, setIsSubscribed } =
    usePaywall()
  const { hideModal } = useModal()
  const { setAlert } = useAlerts()

  useEffect(() => {
    ;(async () => {
      if (tokenizeStatus !== 'success') return
      setProgress(PROGRESS.CARD_INFO_TOKENIZED)
      const [gcid, gsid] = await Promise.all([getGcid(), getGcSessionId()])
      const { data, error } = await subscribe({
        period,
        seats,
        discount: '',
        payer: 'employer',
        reference: paymentMethod?.reference || tokenReference,
        gsid,
        gcid
      }) // start update (was "submitChange")
      if (data?.message === 'ok') {
        const [, company] = data.subscription
        if (company && company.paymentMethod) {
          dispatch(setPaymentMethod(company.paymentMethod))
        }
      } else {
        const { data: errorData } = error
        setAlert({ title: errorData.title, message: errorData.message, kind: 'error' })
        setProgress(PROGRESS.ERROR)
      }
    })()
  }, [tokenizeStatus])

  useEffect(() => {
    if (data?.message === 'ok') {
      setProgress(PROGRESS.SUCCESS)

      removeStoredPaymentmethod()
      setIsSubscribed(true)
      dispatch(setSubscribed())
      const hasValidMobileSubscription = isValidMobileSubscription(subscriptionStatus)
      if (!hasValidMobileSubscription && !subscriptionStatus.webapp.isValid) {
        hideModal('Paywall')
      }
      setTimeout(() => {
        setAlert({ title: 'Success!', message: 'Subscription updated successfully!', kind: 'success' })
      }, 360)
    }
  }, [data, isError])

  return { progress, isLoading, isError }
}
