angular
  .module('starter.business.controllers')
  .controller(
    'documentsCtrl',
    function ($scope, $state, $rootScope, $window, $ngRedux, RefreshTokenNoDebounce, Logout, Service) {
      $scope.user = {}
      $scope.company = {}
      $scope.iFrameStyle = $rootScope.isOverlayVisible ? 'w-full h-full relative z-v2Iframes' : 'w-full h-full relative'
      $rootScope.$watch('isOverlayVisible', () => {
        $scope.iFrameStyle = $rootScope.isOverlayVisible
          ? 'w-full h-full relative z-v2Iframes'
          : 'w-full h-full relative'
      })
      $ngRedux.connect((state) => state.app.user || {})($scope.user)
      $ngRedux.connect((state) => state.app.company || {})($scope.company)
      $ngRedux.connect((state) => ({ gbFeatures: state.app.gbFeatures }))($scope)
      $scope.isLoading = true
      $scope.token = $window.localStorage.pr_token
      const agentId = $window.localStorage.pr_agent_id
      $scope.$watchGroup(['user.e', 'company.e'], () => {
        if (!$scope.user.e && !$scope.company.e) return

        RefreshTokenNoDebounce((tokenResponse, error) => {
          const errorMessage = error?.data?.message
          if (errorMessage === 'Token Expired') {
            Service.alert('Alert', errorMessage, 'error')
            Logout()
            $state.go('login', {
              redirect: window.location.pathname
            })
          } else {
            const generateIframeSrc = () => {
              const { token, expires } = tokenResponse || {}
              const clientId = localStorage.pr_client_id || JSON.parse(localStorage.pr_company)._id
              const queryParams = new URLSearchParams({
                isIframe: true,
                t: token,
                e: expires,
                userType: agentId ? 'agent' : 'employer',
                clientId
              })
              return `${process.env.DEV_V2}/business/documents?${queryParams}`
            }

            const documentsIframe = document.getElementById('documents-v2')
            documentsIframe.src = generateIframeSrc()
            documentsIframe.onload = () => {
              $scope.isLoading = false
              if (!$scope.gbFeatures?.documents_display) $rootScope.$state.go('err404')
              $rootScope.$apply()
            }
          }
        })
      })
    }
  )
