import { DEFAULT_COMPANY_NAME, ONBOARDING_VIDEOS } from '../consts'
import { setModals } from '/reducers/appSlice'
import { logEvent } from '../../modules/analytics'
import { ngGetInitialData } from '/services'
import { getFiscalYear } from '../../utils/getFiscalYear'
import { setOpenModalUpdateSubscription } from '../../reducers/subscriptionSlice'

angular
  .module('starter.business.controllers')
  .controller(
    'runOverviewCtrl',
    function (
      $scope,
      $rootScope,
      Service,
      $state,
      PdfViewerModalService,
      Http,
      Http2,
      HttpFile,
      PayRunAction,
      $q,
      $timeout,
      $ngConfirm,
      employeeModalService,
      AddonService,
      $filter,
      Analytics,
      $ngRedux,
      $window,
      HttpPromiseAndLocalStorage,
      $stateParams
    ) {
      $scope.stateParams = $stateParams
      $scope.appState = {}
      $scope.filteredPayruns = []
      $scope.automatedPayruns = []
      $scope.modals = []
      $scope.isIndustryPopupVisible = false
      $scope.isAutomationOn = false
      $ngRedux.connect((state) => state.app)($scope.appState)
      $ngRedux.connect((state) => ({ modals: state.app.modals }))($scope)
      $scope.$watch('modals', () => {
        if (!$scope.modals.length) {
          $scope.isIndustryPopupVisible = false
          return
        }
        const isIndustryPopupVisible = $scope.modals.every((modal) => modal.kind === 'SelectIndustryModal')
        $scope.isIndustryPopupVisible = isIndustryPopupVisible
      })

      $scope.$watchGroup(['appState.payRuns', 'appState.taxYear'], ([payRuns, taxYear]) => {
        if (payRuns) {
          $scope.filteredPayruns = payRuns.filter(
            (payRun) => getFiscalYear(payRun.pd) === Number(taxYear) && !payRun.isAutomated
          )
          $scope.automatedPayruns = payRuns.filter(
            (payRun) => getFiscalYear(payRun.pd) === Number(taxYear) && payRun.isAutomated
          )
          if ($stateParams.activePayrun) {
            const payrun = payRuns.find((payrun) => payrun._id === $stateParams.activePayrun)
            if (!payrun) {
              Service.alert('Not found', 'Payrun not found', 'error')
              $scope.onClearParams()
              return
            }
            $scope.openEachPayRunModal(payrun)
            $scope.onClearParams()
          }
        }
      })

      $scope.$watch(
        function () {
          return $rootScope.gbFeatures
        },
        function () {
          $scope.isAutomationOn = !!$rootScope.gbFeatures.opt_in_pay_run_automation
        },
        true
      )

      $scope.getInitialData = ngGetInitialData({
        $ngRedux,
        $rootScope,
        Service,
        $window,
        $state,
        Analytics,
        HttpPromiseAndLocalStorage
      })

      $scope.onConfirmAnnualSubscription = () => {
        $ngRedux.dispatch(setOpenModalUpdateSubscription(true))
      }

      $scope.onClearParams = () => {
        $state.go('business.run.overview', {}, { inherit: false })
      }

      $scope.onReloadData = () => {
        $scope.getInitialData()
        $scope.onClearParams()
      }

      $scope.isAccountOlderThan3Months = () => {
        const createdAt = moment($rootScope.user.at)
        const monthDiff = moment().diff(createdAt, 'months', true)
        return monthDiff >= 3
      }
      $scope.amplitudeEvent = (step) => {
        Analytics.logEvent(step, {
          company_id: $rootScope.company._id
        })
        return true
      }
      $scope.openNewEmployeeModal = function () {
        if ($rootScope.gbFeatures.new_employee_creation) $ngRedux.dispatch(setModals([{ kind: 'AddEmployee' }]))
        else if ($rootScope.gbFeatures.loaded) employeeModalService.new()
      }
      $scope.reactState = {
        employeeModalService,
        runNewPayRun: $scope.runNewPayRun,
        onReloadData: $scope.onReloadData,
        onClearParams: $scope.onClearParams
      }
      // $scope.openPdfViewerModal= function(base64){
      // 	Service.modal("pdfViewerModal.html",($scope, $uibModalInstance)=>{
      // 		$scope.cancel = function () {
      // 			$uibModalInstance.dismiss();
      // 		};
      // 		setTimeout(()=>{
      // 			PDFObject.embed(base64, "#example1");
      // 		},500);

      $scope.onRedirectToSandBox = () => {
        logEvent('Demo environment: Clicked on Try demo')
        const form = window.document.createElement('form')
        form.setAttribute('method', 'post')
        form.setAttribute(
          'action',
          `${process.env.API_ENDPOINT}/sandbox/account/employer?token=${$window.localStorage.pr_token}`
        )
        form.setAttribute('target', '_self')

        window.document.body.appendChild(form)
        form.submit()
      }

      $scope.checkCompanySetup = () =>
        $rootScope.company &&
        (!$rootScope.company.c || $rootScope.company.c === DEFAULT_COMPANY_NAME) && // for no abn companies.
        $rootScope.company.wpn == null
      $scope.checkEmployeeSetup = () => $rootScope.employees && $rootScope.employees.length === 0
      $scope.checkPayrollSetup = () => !$rootScope.payRuns || $rootScope.payRuns.length === 0

      $scope.reactStateStep1 = {
        id: 'companySetup',
        data: ONBOARDING_VIDEOS.compnaySetup
      }
      $scope.reactStateStep2 = {
        id: 'employeeSetup',
        data: ONBOARDING_VIDEOS.employeeSetup
      }
      $scope.reactStateStep3 = {
        id: 'payrollSetup',
        data: ONBOARDING_VIDEOS.payRunSetup
      }

      $scope.openDownloadABAFileModal = function (payRunData) {
        Service.modal(
          'downloadABAFileModal.html',
          [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              $scope.cancel = function () {
                $uibModalInstance.dismiss()
              }

              $scope.descriptiveRecord = {
                sequence: 1,
                bank: $rootScope.company.aba.b_co,
                userName:
                  $rootScope.company.c && $rootScope.company.c.length > 26
                    ? $rootScope.company.c.replace(/[^a-zA-Z ]/g, '').substr(0, 26)
                    : $rootScope.company.c.replace(/[^a-zA-Z ]/g, ''),
                userId: $rootScope.company.aba.b_id,
                description: 'Payroll' + moment(payRunData.ed).format('DDMMM')
              }

              $scope.senderBankDetails = {
                fromName: $rootScope.company.aba.b_n,
                fromBsb: $rootScope.company.aba.b_b.substr(0, 3) + '-' + $rootScope.company.aba.b_b.substr(3, 3),
                fromAcc: $rootScope.company.aba.b_no,
                toRef:
                  $rootScope.company.c.replace(/[^A-Z0-9]/gi, '').substr(0, 11) +
                  ' ' +
                  moment(payRunData.ed).format('DDMMM')
              }

              var detailRecords_temp = []
              for (let i = 0; i < payRunData.psl.length; i++) {
                const payslip = payRunData.psl[i]
                var index = Service.findIndexByKeyValue($rootScope.employees, '_id', payslip.ei)
                var employeeData = $rootScope.employees[index]
                if (
                  employeeData &&
                  employeeData.pm === 'b' &&
                  employeeData.b_n &&
                  employeeData.b_b &&
                  employeeData.b_no
                ) {
                  var detailRecord = angular.copy($scope.senderBankDetails)
                  if (payslip.ap > 0) {
                    detailRecord.selected = true
                    detailRecord.amount = payslip.ap
                    detailRecord.toName = employeeData.b_n.replace(/[^A-Z0-9]/gi, '')
                    detailRecord.toBsb = employeeData.b_b.substr(0, 3) + '-' + employeeData.b_b.substr(3, 3)
                    detailRecord.toAcc = employeeData.b_no
                    detailRecords_temp.push(detailRecord)
                  }
                }
              }

              $scope.detailRecords = detailRecords_temp

              $scope.download = function () {
                var detailRecords = []
                for (let i = 0; i < $scope.detailRecords.length; i++) {
                  if ($scope.detailRecords[i].selected) {
                    detailRecords.push($scope.detailRecords[i])
                  }
                }

                if (detailRecords.length === 0) {
                  Service.alert(null, 'Select at least 1 employee', 'error')
                  return false
                }

                Http(
                  {
                    method: 'post',
                    api: '/v1/get-aba-data/',
                    data: {
                      descriptiveRecord: $scope.descriptiveRecord,
                      detailRecords: detailRecords
                    }
                  },
                  (res) => {
                    function download(filename, text) {
                      var element = document.createElement('a')
                      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
                      element.setAttribute('download', filename)

                      element.style.display = 'none'
                      document.body.appendChild(element)

                      element.click()

                      document.body.removeChild(element)
                    }

                    // Start file download.
                    download('Payroll' + moment(payRunData.ed).format('DDMMM') + '.aba', res.abaData)

                    Analytics.logEvent('Download ABA')
                  },
                  (err) => {}
                )
              }
            }
          ],
          { size: 'lg' }
        )

        // return false;
      }

      $scope.openEachPayRunModal = function (payRun) {
        // function openPdfViewerModal(base64){
        // 	// $scope.openPdfViewerModal(base64);
        // 	PdfViewerModalService(base64);
        // }

        console.debug(`pay run id: ObjectId('${payRun._id}')`)

        // draft 일경우 바로 edit mode 로 가기
        if (payRun.s == 'd') {
          $state.go('business.run.payroll', {
            payRunId: payRun._id,
            payPeriod: '',
            startDate: '',
            endDate: '',
            paymentDate: '',
            timesheetImport: ''
          })
          return false
        }

        function openDownloadABAFileModal(PayRun) {
          $scope.openDownloadABAFileModal(PayRun)
        }

        // function openStpDeclarationModal(payRunId){
        // 	$scope.openStpDeclarationModal({
        // 		payRunId: payRun._id,
        // 		amend: true
        // 	});
        // }

        // function openStpErrorModal(errorObj){
        // 	$scope.openStpErrorModal(errorObj);
        // }

        function reportSTP(modalInstance) {
          $scope.reportSTP(payRun, modalInstance)
        }
        // function reportSTP() {
        //   $scope.reportSTP(payRun)
        // }

        Service.modal(
          'eachPayRunModal.html',
          [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              $scope.cancel = function () {
                $uibModalInstance.dismiss()
              }

              if (payRun.stp || payRun.s === 's' || payRun.s === 'sp') {
                $scope.disableDelete = true
              }
              $scope.payRun = angular.copy(payRun)
              // $scope.status = payRun.s;
              // $scope.payrollArray = payRun.psl;
              // $scope.totalPayroll = payRun.tp;

              var paymentOptionConfirm
              $scope.clickPay = function () {
                paymentOptionConfirm = $ngConfirm({
                  title: 'Employee Payment Options',
                  content:
                    "<br><div><button class='btn btn-light btn-block' ng-click='downloadABAFile()'>Download ABA file</button></div>",
                  // "<div class='mt-3'><button class='btn btn-light btn-block' ng-click='downloadABAFile()'>Send via PayPal</button></div>",
                  scope: $scope,
                  buttons: {
                    close: () => {}
                  }
                })
              }
              $scope.downloadABAFile = function () {
                var ngConfirm1

                $scope.goToSettings = function () {
                  $state.go('business.settings')
                  ngConfirm1.close()
                  $scope.cancel()
                }

                if (!$rootScope.company) {
                  Service.alert('Set up your company first.', 'error')
                  return false
                }
                if (!$rootScope.company.aba || !$rootScope.company.aba.b_co) {
                  paymentOptionConfirm.close()
                  ngConfirm1 = $ngConfirm({
                    title: 'Set up your payment file',
                    content:
                      "<br>Go to <a ng-click='goToSettings()' class='text-success'>Settings</a> > Employee Payment Options > Payment File Setting",
                    scope: $scope,
                    buttons: {
                      close: () => {}
                    }
                  })
                  return false
                }

                openDownloadABAFileModal(payRun)
                paymentOptionConfirm.close()
                $scope.cancel()
              }

              $scope.getName = function (firstName, lastName) {
                return firstName + ' ' + (lastName ? lastName.charAt(0).toUpperCase() + '.' : '')
              }

              $scope.viewPaySlip = async function (payslipArray) {
                let retData
                try {
                  retData = await Http2({
                    method: 'post',
                    api: '/v2/create-payslip',
                    data: {
                      payslips: payslipArray,
                      startDate: payRun.sd,
                      endDate: payRun.ed,
                      paymentDate: payRun.pd
                    }
                  })
                } catch (err) {
                  console.error(err)
                  window.Sentry?.captureException(err)
                }
                var filename =
                  payslipArray.length > 1
                    ? 'PayslipsEnding' + $filter('date')(new Date(), 'ddMMMyy', 'UTC')
                    : 'PayslipEnding' +
                      $filter('date')(new Date(), 'ddMMMyy', 'UTC') +
                      (payslipArray[0].f ? '-' + payslipArray[0].f : '')
                PdfViewerModalService(retData.base64, 'Payslip', filename)
                $scope.cancel()
              }

              $scope.sendPaySlip = function (payRun, payslipId) {
                const payRunId = payRun._id
                const tmpPayrun = angular.copy(payRun)
                Service.confirm({
                  title: 'Send payslip',
                  contents: 'Are you sure you want to send a payslip?',
                  confirmText: 'Yes!',
                  cancelText: 'No',
                  confirmCallback: async function () {
                    // $scope.cancel()
                    $scope.loading = true

                    let retData
                    try {
                      retData = await Http2({
                        method: 'post',
                        api: '/v2/send-payslip',
                        data: {
                          payRunId,
                          payslipId
                        }
                      })

                      // Update sent field if Successful.
                      if (retData?.success) {
                        if (tmpPayrun) {
                          const index = tmpPayrun.psl.findIndex((payslip) => payslip._id === payslipId)
                          if (index >= 0) {
                            tmpPayrun.psl[index]['sent'] = new Date().toISOString()
                            $scope.payRun = tmpPayrun
                            PayRunAction.update(tmpPayrun)
                          }
                        }
                      }
                    } catch (error) {}
                    Service.alert('Success!', 'Payslip has been successfully sent!', 'success')
                  },
                  cancelCallback: () => {}
                })
              }

              $scope.bulkSendPayslip = function (payrun) {
                $rootScope.bulkSend(payrun._id, (newPayRun) => {
                  $scope.payRun = newPayRun
                })
              }

              $scope.editPayRun = function () {
                Service.confirm({
                  title: 'Edit pay run',
                  contents: 'Are you sure you want to edit this pay run?',
                  confirmText: 'Yes!',
                  cancelText: 'No',
                  confirmCallback: function () {
                    $scope.cancel()
                    $state.go('business.run.payroll', {
                      payRunId: payRun._id,
                      payPeriod: '',
                      startDate: '',
                      endDate: '',
                      paymentDate: '',
                      timesheetImport: ''
                    })
                  },
                  cancelCallback: () => {}
                })
              }

              $scope.deletePayRun = function () {
                Service.confirm({
                  title: 'Delete pay run',
                  contents:
                    'Please note that this pay run data cannot be restored once you delete it. Would you like to proceed?',
                  confirmText: 'Yes!',
                  cancelText: 'No',
                  confirmCallback: function () {
                    $scope.cancel()
                    Http(
                      {
                        method: 'post',
                        api: '/v1/pay-run/delete/' + payRun._id
                      },
                      (res) => {
                        $scope.loading = false
                        // 저장된 employee data 를 update 한다.
                        PayRunAction.delete(payRun._id)
                      },
                      (err) => {
                        $scope.loading = false
                      }
                    )
                  },
                  cancelCallback: () => {}
                })
              }
              $scope.reportSTP = function () {
                reportSTP($uibModalInstance)
              }
              $scope.sbrPull = () => {
                $scope.cancel()

                $scope.loading = true

                Http(
                  {
                    method: 'post',
                    api: '/v1/pay-run/sbr-pull',
                    data: {
                      payRunId: payRun._id
                    }
                  },
                  (res) => {
                    $scope.loading = false
                    $scope.success = true

                    // PayRunAction.delete(payRun._id)
                    Service.alert('Pay run status updated', 'Pay run was successfully sent to the ATO', 'success')
                    // 저장된 employee data 를 update 한다.
                    PayRunAction.update(res.payRun)
                  },
                  (err) => {
                    $scope.loading = false
                    $scope.success = false

                    var errorTitle = 'Error'
                    var alertType = 'error'

                    if (err && err.code) {
                      switch (err.code) {
                        case 'inProgress':
                          alertType = 'warning'
                          errorTitle = 'In progress'
                          break
                        case 'alreadyCompleted':
                          alertType = 'success'
                          errorTitle = 'Completed'
                          PayRunAction.update(err.payRun)
                          break
                      }
                    }

                    if (err.title && err.title == 'ATO server issue') {
                      STPService.openStpErrorMessageModal({})
                    } else {
                      Service.alert(
                        errorTitle,
                        err && err.message ? err.message : 'STP submission is not successful.',
                        alertType
                      )
                    }

                    $scope.errorMessage = err.message
                  }
                )
                // $scope.reportSTP(payRun)
              }
              var exportingDataToXero = () => {
                $scope.xeroLoading = true
                AddonService.exportXero(
                  payRun,
                  (payRun_new) => {
                    $scope.xeroLoading = false
                    $scope.payRun = payRun_new
                  },
                  (xeroErr) => {
                    $scope.xeroLoading = false
                    if (xeroErr && xeroErr.extra && xeroErr.extra.consentUrl) {
                      window.location.href = xeroErr.extra.consentUrl
                    }
                  },
                  () => {
                    $scope.cancel()
                  }
                )
              }

              $scope.syncXero = function () {
                if (payRun.xero && payRun.xero.at) {
                  Service.confirm({
                    title: 'Re-exporting',
                    contents: 'If you re-export your pay run, another bill will be created. Would you like to proceed?',
                    confirmText: 'Yes, proceed',
                    confirmCallback: function () {
                      exportingDataToXero()
                    },
                    cancelCallback: () => {}
                  })
                } else if (!$rootScope.company.xero || !$rootScope.company.xero.exportAs) {
                  Service.confirm({
                    title: 'Set up ‘Export as’ settings',
                    contents: 'Please set up ‘Export as’ settings in Xero. Would you like to open Xero settings?',
                    confirmText: 'Open Xero Settings',
                    confirmCallback: function () {
                      $scope.cancel()
                      AddonService.openXeroSettings()
                    }
                  })
                } else {
                  exportingDataToXero()
                }
              }
            }
          ],
          { size: 'xxl' }
        )
      }
    }
  )
